import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Layout from '@/layout/index.vue'
import { storage } from '@/utils/tool'
import { useMainStore } from '@/stores/main'
import { authSystem, permissionP, getToken } from '@/api/auth'
import { ElMessage } from 'element-plus'
import { getDictionary } from '@/api/dictionary'
import { getApplicationType } from '@/api/basicInformation'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Layout',
    component: Layout,
    children: [
      {
        path: '',
        name: 'workBench',
        meta: {
          title: '仪表盘'
        },
        component: () => import(/* webpackChunkName: "workBench" */ '@/views/workBench/index.vue')
      },
      {
        path: 'myProfile',
        name: 'myProfile',
        meta: {
          title: '账号信息'
        },
        component: () => import(/* webpackChunkName: "myProfile" */ '@/views/myProfile/index.vue')
      },
      {
        path: '/message',
        name: 'message',
        meta: {
          title: '消息中心'
        },
        component: () => import(/* webpackChunkName: "message" */ '../layout/components/messageCenter.vue')
      }
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '@/views/login/index.vue')
  },
  {
    path: '/404',
    name: '404',
    component: () => import(/* webpackChunkName: "404" */ '@/layout/other/404.vue')
  },
  // {
  //   path: '/:pathMatch(.*)*',
  //   component: () => import(/* webpackChunkName: "404" */ '@/layout/other/404.vue')
  // },
  {
    path: '/SalesAnalysis',
    name: 'SalesAnalysis',
    component: () => import(/* webpackChunkName: "SalesAnalysis" */ '@/views/SalesAnalysis/index.vue')
  }
]

// 菜单扁平化
const flattenMenu = (menu: any) => {
  let result: any = []
  for (let i = 0; i < menu.length; i++) {
    const data = {
      name: menu[i].slug,
      path: menu[i].routePath,
      meta: {
        title: menu[i].title,
        icon: menu[i].icon,
        type: menu[i].type,
        status: menu[i].status
      },
      component: () => import(`@/views/${menu[i].viewPath}`)
    }
    result.push(data)
    if (Array.isArray(menu[i].children)) {
      result = result.concat(flattenMenu(menu[i].children))
    }
  }
  return result
}
// console.log('qiankun:', (window as any).__POWERED_BY_QIANKUN__)
const router = createRouter({
  // 当使用微应用运行的时候，使用`/wsct-inspection`为baseUrl，因为我们在主应用配置的activeRule是`/wsct-inspection`
  history: createWebHistory((window as any).__POWERED_BY_QIANKUN__ ? '/wsct-inspection/' : '/'),
  routes
})
// 获取菜单
const getMenu = async (data: any) => {
  if (!data.length) return
  let menuData = []
  for (let i = 0; i < data.length; i++) {
    const element:any = data[i]
    if (element.slug === 'inspectionSystem') {
      menuData = element.children
      break
    }
  }
  return menuData
}
// 左侧菜单有二级菜单
let haveMenuList: any[] = []
let isFlag = false
router.beforeEach(async (to, from, next) => {
  // 1.0跳转2.0系统携带tokencode
  if (to.query && to.query._tokenCode) {
    await getToken(to.query._tokenCode).then((res: any) => {
      if (res.code === 110200) {
        localStorage.setItem('TOKEN_INSPECT', res.result.token)
        localStorage.removeItem('TOKEN')
      } else {
        ElMessage.error(res.msg)
      }
      next({ path: to.path, query: {} })
    }).catch(() => {
      next({ path: to.path, query: {} })
    })
    return
  }
  // JAVA系统【总台等】跳转过来携带的token
  if (to.query && to.query.info) {
    storage.setItem('TOKEN_INSPECT', to.query.info)
    localStorage.removeItem('TOKEN')
  }

  // 无需权限访问页面
  if (to.path === '/SalesAnalysis') {
    if (storage.getStringItem('TOKEN_INSPECT')) {
      next()
      return
    } else {
      window.open(process.env.VUE_APP_CLOUD_URL, '_self')
      return
    }
  }
  const token = storage.getStringItem('TOKEN_INSPECT') ? storage.getStringItem('TOKEN_INSPECT') : storage.getStringItem('TOKEN') ? storage.getStringItem('TOKEN') : ''
  const store = useMainStore()
  if (to.path === '/login') {
    storage.removeItem('TOKEN_INSPECT')
    storage.removeItem('TOKEN_CLOUD')
    storage.removeItem('TOKEN')
    storage.removeItem('MENU') // 保留一段时间删除todo
    storage.removeItem('PERMISSIONS') // 保留一段时间删除todo
    store.PermissionMenuList = []
    store.permissionsList = []
    store.rolesList = []
    isFlag = false
    next()
    return
  }
  if (token) {
    if (!isFlag) {
      isFlag = true
      await permissionP() // php 接口需要
      // 2.获取权限菜单与按钮权限
      await authSystem().then((res: any) => {
        if (res.code === 100200) {
          store.PermissionMenuList = res.result.menu || []
          store.permissionsList = res.result.permissions || []
          if (res.result.roles) {
            const roles: any = []
            res.result.roles.forEach((element: any) => {
              roles.push(element.slug)
            })
            store.rolesList = roles // 角色 *必须有，需判断KEBS角色
          }
        } else {
          ElMessage.error(res.msg)
        }
      })
      const menuData: any = await getMenu(store.PermissionMenuList) || []
      // **************************当前菜单选中2-1*****************************
      haveMenuList = [] // 左侧菜单有二级菜单
      menuData.forEach((element: any) => {
        if (element.children && element.children.length) {
          let isHave = false
          for (let j = 0; j < element.children.length; j++) {
            if (element.children[j].status === 1) {
              isHave = true
              break
            }
          }
          if (isHave) {
            haveMenuList.push(element.routePath)
          }
        }
      })
      // **************************当前菜单选中2-1 end*****************************
      const menuList = await flattenMenu(menuData)
      menuList.forEach(async (element: any) => {
        await router.addRoute('Layout', element)
      })
      // **************************获取字典 start*****************************
      await getDictionary().then((res: any) => {
        if (res.code === 100200) {
          store.dictionaryList = res.result
        } else {
          ElMessage.error(res.msg)
        }
      })
      // **************************获取字典 end *****************************
      // **************************获取申请类型 start*****************************
      store.currentApplyType = localStorage.getItem('APPLY_TYPE') ? Number(localStorage.getItem('APPLY_TYPE')) : 0
      const lang = localStorage.getItem('LANG') ? localStorage.getItem('LANG') : 'zh_CN'
      await getApplicationType().then((res: any) => {
        if (res.code === 100200) {
          if (res.result && res.result.length) {
            store.inspectAllApplyType = res.result[0].children // 所有申请类型
            res.result[0].children.forEach((element: any) => {
              if (element.itemList) {
                element.itemList.forEach((data: any) => {
                  data.value = data.id
                  data.label = data.name
                })
              }
              element.value = element.id
              element.label = lang === 'en' && element.applicationBriefNameEn ? element.applicationBriefNameEn : element.applicationBriefName
              element.children = element.itemList
              element.applicationBriefName = lang === 'en' && element.applicationBriefNameEn ? element.applicationBriefNameEn : element.applicationBriefName
              // 赋值某项申请类型
              if (store.currentApplyType && element.id === store.currentApplyType) {
                store.currentSelectApplyType = element // 肯尼亚/博兹瓦纳/伊拉克等的申请类型
              }
            })
          }
        } else {
          ElMessage.error(res.msg)
        }
      })
      // **************************获取申请类型 end*****************************
      if (to.query && to.query.info) {
        delete to.query.info
        next({ path: to.path, query: to.query })
      } else if (to.query && to.query.pageNum) {
        // 解决路由中带查询参数的去除数据
        next({ path: to.path, query: {} })
      } else {
        next(to.fullPath)
      }
    } else {
      next()
    }
    // **************************当前菜单选中2-2 start *****************************
    if (to.path === '/') {
      const menuData = await getMenu(store.PermissionMenuList) || []
      const menuList = await flattenMenu(menuData)
      let havaworkBench = false
      menuList.forEach((element: any) => {
        if (element.routePath === '/workBench') {
          havaworkBench = true
        }
      })
      if (havaworkBench) {
        store.activePage = '/workBench'
      } else {
        store.activePage = menuList[0].routePath
        router.push(menuList[0].routePath)
      }
    } else {
      const onPathList = to.path.split('/').slice(1)
      store.activePage = haveMenuList.includes('/' + onPathList[0]) ? `/${onPathList[0]}/${onPathList[1]}` : `/${onPathList[0]}`
    }
    // **************************当前菜单选中2-2 end *****************************
  } else {
    next({ path: '/login' })
  }
})
router.onError((error) => {
  console.log('router.onError:', error)
})
export default router
