import { createAxios } from '@/utils/request'
const request = createAxios()
const basePrefix = process.env.VUE_APP_URL_BASE

// 消息列表
export const messageList = (params: any) => {
  return request({
    method: 'GET',
    url: `${basePrefix}/notify/list`,
    params
  })
}

// 消息详情
export const messageDetails = (data: any) => {
  return request({
    method: 'GET',
    url: `${basePrefix}/notify/${data}`
  })
}

// 消息全部已读
export const allRead = (ids: any = []) => {
  return request({
    method: 'POST',
    url: `${basePrefix}/notify/deal`,
    data: {
      ids
    }
  })
}

// 消息删除
export const messageDel = (data: any) => {
  return request({
    method: 'DELETE',
    url: `${basePrefix}/notify/${data}`
  })
}
