export default {
  common: {
    accessDenied: '拒绝访问',
    pleaseSelect: '请选择',
    pleaseEnter: '请输入',
    select: '选择',
    StartDate: '开始日期',
    StartTime: '开始时间',
    toDate: '到',
    Pleaseenter: '请填写',
    EndDate: '结束日期',
    EndTime: '结束时间',
    operations: '操作',
    detail: '详情',
    edit: '编辑',
    Cancel: '取消',
    Confirm: '确定',
    submit: '提交',
    close: '关闭',
    sure: '确定',
    index: '序号',
    delete: '删除',
    view: '查看',
    status: '状态',
    Search: '搜索',
    Reset: '重置',
    clear: '清空',
    save: '保存',
    noData: '无',
    tips: '提示',
    addBtn: '新建',
    warning: '提示',
    ReceiveSample: '收样',
    ReturnSample: '退样',
    GetSample: '领样',
    SendBack: '退回',
    add: '新增',
    confirmDelete: '确定要删除吗？',
    back: '返回',
    title: '标题',
    noFile: '暂无文件',
    Next: '下一步',
    export: '导出',
    import: '导入',
    agree: '同意',
    files: '文件上传',
    table: '信息表',
    uploadFile: '拖拽文件到区域内或点击上传',
    uploadFormat: '上传格式为jpg/jpeg/png/gif/pdf，大小不得超过3MB,最多上传十个文件',
    uploadNum1: '上传文件数量不能超过',
    uploadNum2: '个',
    uploadSise: '上传文件大小不能超过',
    uploadType1: '文件格式不正确, 请上传',
    uploadType2: '格式文件',
    uploadTip1: '请上传',
    uploadTip2: '大小不超过',
    uploadTip3: '格式为',
    uploadTip4: '的文件',
    uploadFail: '上传失败, 请重试',
    confirmDeleteAll: '确定要清除所有信息吗？',
    filtrate: '筛选',
    columnSettings: '列设置',
    unknownError: '未知错误',
    sort: '排序',
    rfcNo: 'RFC No.',
    id: 'ID',
    year: '请选择年份',
    mergeSubmit: '合成报告请保存',
    exportSuccessful: '导出成功',
    exportFailure: '导出失败',
    days: '天',
    hours: '时',
    minutes: '分',
    seconds: '秒',
    generate: '生成',
    completeInfo: '请完善',
    completeRequired: '必填项'
  },
  request: {
    401: '未授权',
    403: '拒绝访问',
    404: '请求错误,未找到该资源',
    405: '请求方法未允许',
    408: '请求超时',
    500: '服务器端出错',
    501: '网络未实现',
    502: '网络错误',
    503: '服务不可用',
    504: '网络超时',
    505: 'http版本不支持该请求',
    linkError: '连接错误',
    timeout: '服务器响应超时，请稍后重试',
    linkServerError: '连接服务器失败'
  },
  form: {
    pleaseInput: '请输入内容',
    sealNo: '锁号共6位',
    unit: '单位不能为空',
    inputNumber: '对应注册证书(SOR)/许可证书(SOL)/产品证书(PC)号码'
  },
  about: {
    laboratory: '实验室模块',
    list: '列表',
    homepage: '主页',
    Refresh: '刷新',
    screen: '搜索',
    SerialNumber: '序号',
    title: '标题',
    CertificateType: '证书类型',
    Applicant: '申请商',
    manufacturer: '制造商',
    PlantInformation: '工厂信息',
    corporateName: '公司名称',
    RectificationRecords: '整改记录',
    BusinessStatus: '业务状态',
    CreationTime: '创建时间',
    operate: '操作',
    ToBeDispatch: '签派实验室',
    AddLab: '添加实验室',
    CaseNumber: '开案号',
    DispatchObject: '派遣到实验室',
    PleaseInputDispatchDept: '请选择派遣部门',
    Head: '负责人',
    PleaseInputDispatchHead: '请选择派遣负责人',
    describe: '描述',
    PleaseInputDescribe: '请输入描述',
    resetting: '重置',
    submit: '提交',
    ToBeUploaded: '待上传',
    uploadTestReport: '上传草稿测试报告',
    reporType: '报告类型',
    file: '文件',
    IsItAbnormal: '是否异常',
    notes: '备注',
    UploadAttachments: '上传附件',
    preliminary: '报告初审',
    reEvaluation: '报告复审',
    abnormalDetails: '异常详情',
    abnormalRectification: '异常整改',
    Dispatch: '签派',
    UploadRawTestData: '上传原始测试数据',
    preliminaryExamination: '初审',
    PreliminaryReviewDetails: '初审详情',
    reexamine: '复审',
    ReviewDetails: '复审详情',
    PreliminaryEvaluationResults: '初评结果',
    pass: '通过',
    reject: '驳回',
    NoPass: '不通过',
    ReasonForFailure: '不通过原因',
    rawData: '原始数据',
    TestDraftReport: '测试草稿报告',
    PassedOrNot: '是否通过',
    uploadFile: '文件上传',
    ReviewResults: '复评结果',
    Burundi: '布隆迪—ARCT',
    AbnormalFile: '异常文件',
    AbnormalRectificationStatus: '异常整改状态',
    Rectification: '整改',
    view: '查看',
    BeforeRectification: '文件（整改前）',
    AfterRectification: '文件（整改后）',
    ExceptionDescription: '异常说明',
    rawDatades: '原始数据详情',
    TestDraftReportdes: '草稿测试报告详情',
    not: '无'
  },
  header: {
    outLogin: '退出成功',
    exit: '退出',
    exitText: '确认是否退出当前用户？',
    AccountInformation: '账号信息',
    ChangePassword: '修改密码',
    LogOut: '退出登录',
    systemName: '检验管理系统',
    businessType: '业务类型',
    oldVersion: '切换旧版本'
  },
  login: {
    slogan: '我们是WSCT',
    describe: '——国际公认的检测、认证、鉴定和检验机构',
    signInTitle: '用户登录',
    accountLogin: '账号登录',
    mobileLogin: '手机号登录',
    Scancodelogin: '企业微信登录',
    rememberMe: '24小时免登录',
    forgetPassword: '忘记密码',
    signIn: '登录',
    signInOther: '其他登录方式',
    userPlaceholder: '用户名',
    userError: '请输入用户名',
    PWPlaceholder: '请输入密码',
    PWError: '请输入密码',
    PWnum: '密码最少6位',
    Codelaceholder: '请输入验证码',
    CodeError: '请输入验证码',
    errorcode: '验证码错误',
    admin: '管理员',
    user: '用户',
    mobilePlaceholder: '手机号码',
    mobileError: '请输入手机号码',
    smsPlaceholder: '短信验证码',
    smsError: '请输入短信验证码',
    smsGet: '获取验证码',
    smsSent: '已发送短信至手机号码',
    noAccount: '还没有账号?',
    createAccount: '创建新账号',
    wechatLoginTitle: '二维码登录',
    wechatLoginMsg: '请使用微信扫一扫登录 | 模拟3秒后自动扫描',
    wechatLoginResult: '已扫描 | 请在设备中点击授权登录',
    loginSuccess: '登录成功',
    logoutSuccess: '退出登录成功'
  },
  issuance: {
    Route: 'Route',
    ConfirmationIssuance: '确认签发',
    refuseIssuance: '拒绝签发',
    detail: '证书详情',
    type: '申请类型',
    date: '签发日期',
    pleaseDate: '请输入签发日期',
    applicant: '申请商',
    manufacturer: '制造商',
    factory: '工厂信息',
    auditor: '审核人',
    pay: '支付情况',
    status: '状态',
    certificate: '证书签发',
    upload: '证书上传',
    book: '证书正本',
    mook: '拟稿',
    mookView: '拟稿查看',
    acceptor: '受理人',
    voc: 'VOC证书号',
    vision: '版本',
    sign: '签发',
    certificateType: '证书类型',
    certificateId: '证书编号',
    company: '公司名称',
    signer: '签发人',
    download: '下载',
    pleaseFile: '请上传.pdf格式的文件',
    isCheck: '审核结果',
    checkBtn: '通知中期审核',
    viewBook: '查看证书',
    checkInfo: '请确定通知审核？',
    nodate: '暂无数据',
    tips: '备注：证书内容上传，只需要上传证书内页即可',
    reissue: '重新签发',
    reissueInfo: '确定重新签发？重新签发将需要再次从操作拟稿，证书签发2个业务步骤',
    draftLoad: '正在生成证书，请稍等',
    certificatePreview: '证书预览',
    pealseRemark: '请输入备注',
    pealseFile: '请上传证书',
    isView: '请选择证书状态为 "查看" 的数据进行重新签发',
    singleSelect: '请选择一条数据进行重新签发',
    importer: '进口商',
    exporter: '出口商',
    router: 'Route',
    CreatedTime: '创建时间',
    address: '验货地址',
    transport: '运输方式',
    inspector: '验货员',
    ICProcessor: 'IC受理员',
    iaf: 'IAF评估人',
    iafOver: 'IAF已评',
    pteOver: 'PTE 已评',
    mookFile: '拟稿文件',
    modifyBook: '修改证书',
    reason: '拒绝原因',
    signRecord: '证书签发记录',
    refreshBook: '证书刷新',
    textExport: 'TEXT 导出',
    Description: '描述',
    pleaseDescription: '请输入描述',
    modifyType: '修改类型',
    rawData: '原数据',
    newData: '新数据',
    modifyCertFile: '修改发票所属附件上传',
    updateCertFile: '更新发票所属附件上传',
    updateBook: '更新证书',
    Overtime: '已超过',
    Notissuance: '小时未签发',
    Notyetissued: '还未签发',
    SorCert: 'SOR/SOL证书号',
    success: '成功',
    checkOne: '请选择一条数据进行证书刷新',
    pushitem: '确认要重新推送证书信息吗？请先确认相关信息是否补充完整',
    pusherror: '业务异常',
    changCert: '请选择一条数据进行证书修改',
    UpdateCert: '请选择一条数据进行证书更新',
    method: '方法',
    certfailreason: '失败原因',
    days: '天',
    pushStatus: '推送状态',
    successstatus: '成功',
    failstatus: '失败',
    signTime: '签发时间',
    waitCCT: '待签派CCT',
    noPayInfo: '此客户未全部付款，请联系财务；',
    noIDInfo: '此客户没有分配标识，请联系财务客户管理负责人(张士舒、谢婕)!',
    customerBlack: '此客户在黑名单当中（永久加入黑名单）!',
    customerBlackSuspend: '此客户在黑名单当中（暂停）!',
    noCheckMonthlyReview: '此客户月度批量结算审核待初审,请联系财务及时处理!',
    noMonthlyReview: '此客户月度批量结算审核初审不通过,请联系财务及时处理!',
    noReviewCheckMonthlyReview: '此客户月度批量结算审核待复审,请联系财务及时处理!',
    noReviewMonthlyReview: '此客户月度批量结算审核复审不通过,请联系财务及时处理!',
    noCheckSettlementApproval: '此客户批量结算方式审批财务待审核,请联系财务及时处理!',
    noSettlementApproval: '此客户批量结算方式审批未通过,请联系财务及时处理!',
    noManagerSettlementApproval: '此客户批量结算方式审批待财务经理审批,请联系财务及时处理!'

  },
  certificateIssuance: {
    type: '申请类型',
    Route: 'Route',
    company: '公司名称',
    importer: '进口商',
    exporter: '出口商',
    transport: '运输方式',
    ICProcessor: 'IC受理员',
    inspector: '验货员',
    iaf: 'IAF/PTD',
    Inspectiontime: '验货时间',
    status: '状态',
    auditStatus: '财务状态',
    issueDate: '签发日期',
    NumberofLine: 'Number of Line',
    mookFile: '拟稿文件',
    book: '证书正本'
  },
  certificateManagement: {
    idf: 'IDF No.',
    ucr: 'UCR No.',
    coc: 'Certifate No.',
    issueDate: '签发日期',
    InspectionDate: 'Inspection Date',
    company: '公司名称',
    importer: '进口商',
    exporter: '出口商',
    Route: 'Route',
    transport: '运输方式',
    ICProcessor: 'IC受理员',
    NumberofLine: 'Number of Line',
    ExportCopy: 'Export Copy',
    book: '证书正本',
    KEBSApiSend: 'KEBS Api Send',
    issueStart: '发证日期',
    issueEnd: '证书截至日期'
  },
  basicInformation: {
    cancelOrder: '取消订单',
    suspendOrder: '暂停订单',
    rfcNo: 'RFC No.',
    applyType: '申请类型',
    applicant: '申请商',
    factory: '工厂信息',
    product: '产品名称',
    createdTime: '创建时间',
    status: '状态',
    acceptance: '审核受理',
    adjustProcess: '单据调流程',
    suspendReason: '暂停原因',
    cancelReason: '取消原因',
    serverType: '服务类型',
    returnReason: '退回原因',
    sureCancel: '确定取消RFC NO. :',
    sureSuspend: '确定暂停RFC NO. :',
    sureMessage: '这条订单吗？',
    pleaseSelectCancel: '请先选择一条数据再取消订单！',
    pleaseSelectSuspend: '请选择一条数据再暂停订单！',
    pleaseSelectAdjustment: '请选择一条数据进行单据调流程操作！',
    pleaseSelectRecheck: '请选择一条数据进行重验操作！',
    pleaseSelectColunm: '请先选择一条数据再进行操作！',
    cancelSuccess: '此订单已取消',
    suspendSuccess: '此订单已暂停',
    accept: '受理',
    sendBack: '退回',
    acceptTip: '提示：受理/退回都会给客户发送通知，其中退回原因，会展示给客户！',
    // 受理
    pleaseEnterSendRemarks: '请输入退回原因',
    pleaseSelectStatus: '请选择审核受理状态',
    // 调流程
    pleaseEnterRemarks: '请输入备注信息',
    pleaseSelectOrderStatus: '请选择委托单状态',
    // 标记优先
    flagPriority: '标记优先',
    flagPriorityTip: '标记优先，订单颜色变为绿色，提醒业务关注，确定标记所选项？',
    productTip: '* 产品明细为分页展示，请分页编辑并保存',
    updateTime: '更新时间',
    exportWarning: '不可全部导出，可以选择一条、多条或按日期等筛选后再导出!'
  },
  applicationForm: {
    placeOrdersOnline: '在线下单，快捷管理订单',
    draftsDetailTitle: '申请草稿详情',
    statusDetailTitle: '申请办理情况详情',
    businessGuide: '业务申请指南',
    importantNote: '重要提示: 此表格必须在货物装运前填写并提交给WSCT',
    FillOutTheFormOnlineHint: '在线填写表单或调用历史数据或下载模板填写后再提交',
    country: '国家',
    certificateType: '证书类型',
    choose: '选择',
    createType: '创建类型',
    selectFormTypeTitle: '参考信息',
    applicationFormTitle: '基础信息',
    applicationFormTitle2: '基础信息',
    UploadTitle: '验货申请单所属形式发票上传',
    downloadTemplate: '下载模板',
    import: '导入',
    saveDraft: '保存为草稿',
    pleaseChoose: '请点击按钮进行选择',
    createMode: '创建方式',
    createForm: '创建表单',
    importApplicationData: '导入历史申请数据',
    uploadText: '点击或者拖拽文件',
    upload2: '上传',
    uploadTip: '上传格式为jpg/gif/png/bmp/doc/docx/xlsx/xls/pdf/txt/zip/tar并且大小不超过30MB',
    uploadDocument: '上传附件',
    documentText: '附件',
    documentTip: '装箱单 / IDF文件 / 质量文件 / 其他文件',
    documentMessage01: '1、上传的每个附件大小不超过100M;否则将无法正常提交。',
    documentMessage02: '2、支持的文件类型:jpg、gif、png、bmp、doc、docx、xlsx、xls、pdf、txt、zip、tar。',
    fileType: '文件类型',
    fileName: '文件名称',
    size: '大小',
    remarks: '备注',
    companyName: '公司名称',
    companyNameEn: '公司名称(英文)',
    city: '城市',
    address: '地址',
    zipCode: '邮编',
    contactName: '联系人',
    telephone: '电话号码',
    telephonePlaceholder: '示例：+86-10-8888888 或 86-010888888',
    email: '邮箱',
    fax: '传真',
    chooseHistoricalData: '选择历史数据',
    currentApplicationCannotEdit: '当前认证申请书状态不可编辑,请联系业务员进行操作',
    timeCannotEarlier: '时间不能早于当前日期',
    timeCannotToday: '时间不能超过当前日期',
    pleaseImproveProduct: '请完善产品信息：',
    limitFilesNum: '每次最多只能上传5个附件',
    sureSaveDraft: '确定存入草稿吗？',
    uploadSuccess: '上传成功',
    uploadError: '上传失败',
    toModify: '去修改',
    haveFailDocument: '个附件正在上传或上传失败，是否确定提交？',
    reupload: '重新上传',
    excelImport: 'Excel导入',
    improvedocumentInfo: '请输入附件必填信息',
    upload20Attachments: '温馨提示：最多上传二十个附件！',
    selectHistoryData: '请选择历史申请数据',
    selectApplyType: '请选择申请类型',
    pleaseUpdateTemplate: '请导入Excel模板',
    saveToDraft: '存入草稿',
    oneClickTop: '一键置顶',
    // 附件信息
    selectFileType: '请选择文件类型',
    pleaseUploadFile: '请上传附件',
    // 详细信息
    inputCompanyName: '请输入公司名称',
    inputCompanyNameEn: '请输入公司名称（英文）',
    validateZipCode: '请输入邮政编码',
    validateZipCodeFormat: '请输入4-6位数字的邮政编码',
    countryName: '请输入国家',
    cityName: '请输入城市',
    inputAddress: '请输入地址',
    inputContact: '请输入联系人',
    inputTelephone: '请输入电话号码',
    inputEmail: '请输入邮箱',
    inputEmailReg: '请输入正确的邮箱格式',
    inputTelephoneReg: '格式为4-20位的数字、特殊字符+-',
    // 加载
    loading: '加载中...',
    submitting: '提交中...',
    uploading: '上传中...',
    // 用户条款
    userTerms: '用户条款',
    companyAddress: '，地址，',
    ourCompany: '我司，',
    userTermsStated: '，在此特别申明，本次申报的产品不受召回产品的影响，也不是不合格品或假冒伪劣产品，我们对所有申报信息的真实性承担责任。 同时，我们也深知进口国对这些产品进口的监管要求并承诺遵守这些要求。',
    userTermsInfo: '提交信息后，WSCT会尽快受理申请单并且邮件联系您，请确认是否此邮箱',
    usreTermsInfo2: '能联系到您，如不是，请在下方填写，多个邮箱以“；”隔开。',
    // 认证
    applicationDonotEdit: '当前认证申请书状态不可编辑,请联系业务员进行操作',
    addApplicationSaveSuccess: '自动保存成功',
    addApplicationSaveError: '自动保存失败',
    addApplicationSubmitSuccess: '提交成功',
    addApplicationSubmitError: '提交失败',
    addApplicationDraftsSuccess: '保存草稿成功',
    addApplicationDraftsError: '保存草稿失败',
    completeApplicationContent: '请完善认证申请书必填内容',
    pleaseSelectCustomer: '请先选择申请人',
    pleaseEnterKeyword: '请输入公司名称关键字查询后选择',
    pleaseSelectKeyword: '请选择申请人',
    // 调流程
    orderStatus: '委托单状态',
    // 历史数据
    selectHistory: '选择历史申请数据',
    invoiceInfo: '发票信息（最终发票或形式发票）',
    // 暂停订单
    recover: '恢复',
    currentStatus: '当前状态',
    beforeSuspendStatus: '暂停前状态',
    restoreTip1: '确定恢复订单',
    restoreTip2: '为“',
    restoreTip3: '”状态吗?',
    // 附件
    oneclickDownload: '一键下载',
    recoverFile: '恢复文件',
    addFile: '添加文件',
    whetherToAdopt: '是否采纳',
    publicOrNot: '是否公开',
    // 委托申请人
    accountNumber: '账号',
    // 附件
    pleaseSelectCreateTime: '请选择时间',
    titleitem: '您给自己的申请起的名字，便于您查询和区分不同的申请，两个申请的申请标题不能重复。',
    titleitemone: '您可以采用便于自己统计和查询的方式命名举例: 1)公司名称缩写-产品缩写-编号;2)商标-日期-编号。',
    // 当地商业登记号
    localBusinessRegistrationNumber: '当地商业登记号',
    // 进口商/收货人是伊拉克政府部门或伊拉克相关行政部门
    importersDepartments: '进口商/收货人是伊拉克政府部门或伊拉克相关行政部门',
    // 装运和检验信息：
    transportformation: '装运和检验信息',
    // 交货类型
    deliveryType: '交货类型',
    // Transport mode
    transportModeone: '运输方式',
    // 装运方式
    loadingType: '装运方式',
    // 供应国
    supplyCountry: '供应国',
    // 预计装运日期
    estimatedLoadingDate: '预计装运日期',
    // 货物供应日期
    goodsSupplyDate: '货物供应日期',
    // 装运方式和进入伊拉克中部的地点（必须填写进入地点信息）：
    loadingLocation: '装运方式和进入伊拉克中部的地点（必须填写进入地点信息）',
    // 以下入口点位于伊拉克中部
    belowEntryPoints: '以下入口点位于伊拉克中部',
    // 注：货物应在COC签发后迅速交付。到期日期（如有）、剩余保质期和测试报告的有效期（如有要求）应符合抵达日期的适用标准（更多详细信息，请参阅适用标准和“VOC伊拉克-出口商须知”）
    note: '注：货物应在COC签发后迅速交付。到期日期（如有）、剩余保质期和测试报告的有效期（如有要求）应符合抵达日期的适用标准（更多详细信息，请参阅适用标准和“VOC伊拉克-出口商须知”）',
    // 包装数量及单位
    packingQuantityAndUnit: '包装数量及单位',
    // 包装数量
    packingQuantity: '包装数量',
    // 包装单位
    packingUnit: '包装单位',
    // 附件保存成功
    attachmentsSaved: '附件保存成功',
    // 订单类型更改， 请去重新报价。
    changeItem: '订单类型更改， 请去重新报价。',
    // Letter of Credit
    letterOfCredit: '信用证',
    // Bill of Lading
    billOfLading: '提单'
  },
  // 验货相关字段
  inspectionData: {
    route: 'Route',
    importer: '进口商',
    importerPlaceholder: '请输入进口商',
    exporter: '出口商',
    exporterPlaceholder: '请输入出口商',
    applicant: '公司名称',
    placeOfInspection: '验货地址',
    hasIDFNumber: '是否已获得IDF号码',
    IDFNo: 'IDF No.',
    UcrNo: 'UCR No.',
    mailCertificateCollection: '邮件证书领取',
    shipmentMethod: '运输方式',
    cargoType: '出运方式',
    containerInfo: '货柜信息',
    placeOfLoading: '装运港',
    placeOfDischarge: '卸货港',
    countryOfSupply: '供应国',
    countryOfOrigin: '起运国',
    quantityDelivered: '交付量',
    orderStatus: '委托单状态',
    companyName: '公司名称',
    updateTime: '更新时间',
    cargoDescription: '货物描述',
    standardNumber: '标准号',
    invoiceNo: 'Invoice No.',
    customsCode: '海关编码',
    dllNo: 'DLL No.',
    importerPin: '进口商 Pin',
    exporterPin: '出口商 Pin',
    beneficiary: '受益方',
    creatorName: '创建人名称',
    submitTime: '提交时间',
    createdTime: '创建时间',
    applyType: '申请类型',
    productRiskOptions: '产品风险选项',
    inspectionAddressRiskOptions: '验货地址风险选项',
    financeAuditStatus: '财务审核状态',
    isDispatch: '是否签派',
    dispatched: '已签派',
    IAFview: 'IAF结果',
    inspectionDispatchViewing: '验货签派查看',
    notDispatched: '未签派',
    source: '来源',
    customer: '客户',
    backstage: '后台',
    manufacturer: '制造商',
    factory: '工厂商'
  },
  productConformity: {
    placeOrdersOnline: '在线下单，快捷管理订单',
    draftsDetailTitle: '申请草稿详情',
    statusDetailTitle: '申请办理情况详情',
    businessGuide: '业务申请指南',
    importantNote: '重要提示: 此表格必须在货物装运前填写并提交给WSCT',
    FillOutTheFormOnlineHint: '在线填写表单或调用历史数据或下载模板填写后再提交',
    basicContentsForm: '验货申请单基础内容',
    uploadAttachmentAndInvoice: '上传检验申请表所需形式发票和附件',
    uploadAttachmentAndInvoice2: ' 检验申请表所需形式发票和附件',
    uploadAttachment: '上传检验申请表所需附件',
    uploadAttachment2: '检验申请表所需附件',
    pleaseEnterParties: ' 请输入各贸易方信息',
    pleaseEnterParties2: ' 各贸易方信息',
    finalInvoice: '形式发票',
    itemsSending: '添加出货产品的信息（完整申报所有产品信息）',
    itemsSending2: '出货产品的信息（完整申报所有产品信息）',
    createType: '创建类型',
    selectFormTypeTitle: '参考信息',
    applicationFormTitle: '填写申请表基本内容',
    applicationFormTitle2: '申请表基本内容',
    UploadTitle: '验货申请单所属形式发票上传',
    downloadTemplate: '下载模板',
    import: '导入',
    saveDraft: '保存为草稿',
    addInvoice: '新增发票',
    invoiceText: '发票',
    invoiceRemind: '必须添加至少一个发票信息',
    upload: '上传',
    invoiceFile: '发票文件',
    invoiceNO: '发票号码',
    invoiceDate: '发票日期',
    currency: '币种',
    amount: '总金额',
    incoterm: '国际贸易术语',
    uploadText: '点击或者拖拽文件',
    upload2: '上传',
    uploadTip: '上传格式为jpg/gif/png/bmp/doc/docx/xlsx/xls/pdf/txt/zip/tar并且大小不超过30MB',
    uploadDocument: '上传附件',
    documentText: '附件',
    documentTip: '装箱单 / IDF文件 / 质量文件 / 其他文件',
    documentMessage01: '1、上传的每个附件大小不超过100M;否则将无法正常提交。',
    documentMessage02: '2、支持的文件类型:jpg、gif、png、bmp、doc、docx、xlsx、xls、pdf、txt、zip、tar。',
    fileType: '文件类型',
    fileName: '文件名称',
    size: '大小',
    remarks: '备注',
    companyName: '公司名称',
    companyNameEn: '公司名称(英文)',
    country: '国家',
    city: '城市',
    address: '地址',
    zipCode: '邮编',
    contactName: '联系人',
    telephone: '电话号码',
    telephonePlaceholder: '示例：+86-10-8888888 或 86-010888888',
    email: '邮箱',
    fax: '传真',
    payWSCTService: '支付WSCT服务费用',
    exporterDetails: '卖家/出口国详情',
    placeOfInspection: '检验地点',
    chooseHistoricalData: '选择历史数据',
    importerDetails: '买家/进口国详情',
    warehouseName: '仓库名称',
    warehouseNameEn: '仓库名称(英文)',
    zoneProvince: '区域/省份',
    originalRegion: '原地区/省',
    GoodsAvailableDate: '预计检验日期',
    pleaseSelectRfcType: '请先选择申请类型',
    beneficiary: '受益方',
    addProduct: '添加产品',
    editProduct: '编辑产品',
    HSCode: '海关编码',
    brand: '品牌',
    modelNumber: '型号',
    quantity: '数量',
    unitPrice: '单价',
    unit: '单位',
    goodsDescription: '货品描述',
    manufacturerName: '制造商名称',
    manufacturerAddress: '制造商地址',
    countryOfOrigin: '原产国',
    goodsCondition: '货物状况',
    assemblyState: '组装状态',
    cargoCondition: '货物状态',
    supervisoryDepartment: '监管部门',
    ApplicableStandard: '适用标准',
    brOfRegistration: '可提供证书',
    correspondingCertificate: '对应注册证书（SOR）/许可证书（SOL）/产品证书（PC）号码',
    ClearAllItems: '清除所有信息',
    getTemplate: '下载模板',
    importItems: '导入数据',
    productList: '产品列表',
    uploadProductTip: '导入数据文件格式为xlsx并且大小不超过20MB',
    containerSize: '货柜尺寸',
    containerQuantity: '货柜数量',
    applyTypeItem: '请选择申请类型',
    getIdfNo: '请选择是否已获得IDF号码',
    idfNo: '请输入IDF No.',
    ucrNo: '请输入UCR No.',
    needCertPerson: '请选择邮件证书领取',
    transportMode: '请选择运输方式',
    packingMethod: '请选择出运方式',
    containerData: '请输入货柜信息',
    supplierCountry: '请选择供应国',
    selectCorrectData: '请选择正确的',
    registLicenseNumber: '注册许可证编号',
    // 发票信息
    invoicePath: '请上传发票',
    invoiceNo: '请输入发票号码',
    selectInvoiceDate: '请选择发票时间',
    selectTradeTerm: '请选择国际贸易术语',
    selectCurrency: '请选择币种',
    invoiceTotal: '请输入总金额',
    article: '第',
    uploadInvoiceError: '条发票文件上传失败',
    // 附件信息
    selectFileType: '请选择文件类型',
    pleaseUploadFile: '请上传附件',
    // 详细信息
    inputCompanyName: '请输入公司名称',
    inputCompanyNameEn: '请输入公司名称（英文）',
    inputWarehousename: '请输入仓库名称',
    inputWarehousenameEn: '请输入仓库名称(英文)',
    countryName: '请输入国家',
    cityName: '请输入城市',
    inputAddress: '请输入地址',
    inputContact: '请输入联系人',
    inputTelephone: '请输入电话号码',
    selectPostDate: '请选择预计检验日期',
    inputEmail: '请输入邮箱',
    inputEmailReg: '请输入正确的邮箱格式',
    inputTelephoneReg: '格式为4-20位的数字、特殊字符+-',
    inputRegionOrPrice: '请输入区域/价格',
    // 产品信息
    inputHSCode: '请输入海关编码',
    inputBrandName: '请输入品牌',
    inputModel: '请输入型号',
    inputQuantity: '请输入数量',
    inputPrice: '请输入单价',
    selectUnit: '请选择单位',
    inputGoodsDescription: '请输入货品描述',
    inputManufacturerName: '请输入制造商名称',
    inputManufacturerAddress: '请输入制造商地址',
    selectOriginCountry: '请选择原产国',
    selectFreightStatus: '请选择货物状况',
    selectAssembleStatus: '请选择组装状态',
    inputSort: '请输入排序编号',
    selectHistory: '选择历史申请数据',
    importExcel: '上传格式为xlsc并且大小不超过20MB',
    noCountryText: '未查询到国家信息',
    applicationDonotEdit: '当前认证申请书状态不可编辑,请联系业务员进行操作',
    addApplicationSaveSuccess: '自动保存成功',
    addApplicationSaveError: '自动保存失败',
    addApplicationSubmitSuccess: '提交成功',
    addApplicationSubmitError: '提交失败',
    addApplicationDraftsSuccess: '保存草稿成功',
    addApplicationDraftsError: '保存草稿失败',
    completeApplicationContent: '请完善认证申请书必填内容',
    lessAddOneProduct: '至少添加一个产品项目',
    pleaseAddInvoice: '请添加发票信息',
    improvedocumentInfo: '请输入附件必填信息',
    upload20Attachments: '温馨提示：最多上传二十个附件！',
    selectHistoryData: '请选择历史申请数据',
    selectApplyType: '请选择申请类型',
    pleaseUpdateTemplate: '请导入Excel模板',
    whetherInspect: '是否远程验货',
    pleaseSaveOrEdit: '请保存或删除编辑状态的附件信息',
    maxInvoiceText: '最多上传三个发票信息',
    currentApplicationCannotEdit: '当前认证申请书状态不可编辑,请联系业务员进行操作',
    timeCannotEarlier: '时间不能早于当前日期',
    timeCannotToday: '不能超过当前日期',
    pleaseImproveProduct: '请完善产品信息：',
    limitFilesNum: '每次最多只能上传5个附件',
    sureSaveDraft: '确定存入草稿吗？',
    uploadSuccess: '上传成功',
    uploadError: '上传失败',
    toModify: '去修改',
    haveFailDocument: '个附件正在上传或上传失败，是否确定提交？',
    reupload: '重新上传',
    excelImport: 'Excel导入',
    exportProduct: '导出产品',
    productManagement: '产品管理',
    model: 'Model',
    importProduct: '导入产品',
    // 运输信息
    packagingInformation: '包装信息',
    packageQuantity: '包装数量',
    transportContainerNumber: '运输柜号',
    acid: 'ACID',
    importersTaxNumber: '进口商税号',
    exporterID: '出口商ID',
    exportCountry: '出口国家',
    exportCountryCode: '出口国家代号',
    limitContainerNo: '最多只能添加10个运输柜号',
    limitContainerNoItem: '运输柜号为字母和数字并且长度不超过20',
    pleaseInputTransportNum: '请输入运输柜号，按Enter键添加',
    pleaseInputacid: '请输入ACID',
    pleaseSelectpackaging: '请选择包装信息',
    pleaseInputPackageNum: '请输入包装数量',
    pleaseInputImportersTax: '请输入进口商税号',
    pleaseInputExportID: '请输入出口商ID',
    pleaseSelectExportCode: '请选择出口国家代号',
    inputAfterEnter: '输入后按Enter键添加',
    // 加载
    loading: '加载中...',
    submitting: '提交中...',
    uploading: '上传中...',
    // 用户条款
    userTerms: '用户条款',
    companyAddress: '，地址，',
    ourCompany: '我司，',
    userTermsStated: '，在此特别申明，本次申报的产品不受召回产品的影响，也不是不合格品或假冒伪劣产品，我们对所有申报信息的真实性承担责任。 同时，我们也深知进口国对这些产品进口的监管要求并承诺遵守这些要求。',
    userTermsInfo: '提交信息后，WSCT会尽快受理申请单并且邮件联系您，请确认是否此邮箱',
    usreTermsInfo2: '能联系到您，如不是，请在下方填写，多个邮箱以“；”隔开。',
    // 发票信息补充
    finalInvoiceTitle: '最终发票信息补充',
    invoiceNumber: '发票号码',
    invoiceAmount: '发票金额',
    exchangeRate: '汇率',
    invoiceTime: '发票日期',
    grossWeight: '总重量',
    noteExchangeRete: '注:货币为欧元时需填写此汇率，EUR/肯尼亚先令',
    noteExchangeReteIQ: '注:货币为欧元时需填写此汇率，EUR/肯尼亚先令',
    // 恢复文件
    deleteTime: '删除时间',
    AttachmentInfo: '附件信息',
    otherInfo: '各方信息',
    // 报价过的申请单修改币种后提示
    notice: '注意',
    changeInvoiceTip: '更改了形式发票金额、币种，或者正式发票金额、币种，会影响报价，请尽快前往报价页面重新报价！',
    changeInvoiceTipone: '更改了证书类型会影响报价，请尽快前往报价页面重新报价！',
    productHaveWSCT: '产品列表中可提供证书有非WSCT签发证书，是否清空？',
    // 产品管理
    pleaseInputOrderId: '排序不能为空',
    // 请选择装运方式
    pleaseSelectLoadingType: '请选择装运方式',
    // 请选择运输方式
    pleaseSelectTransportMode: '请选择运输方式',
    // 国际标准
    internationalStandard: '国际标准',
    //  国家标准
    nationalStandard: '国家标准',
    // 企业标准
    enterpriseStandard: '企业标准',
    // 客户标准
    customerStandard: '客户标准',
    // 个人标准
    personalStandard: '个人标准',
    // 其他标准
    otherStandard: '其他标准',
    containerMsg: '请只输入一项货柜信息'
  },
  communication: {
    scopeOfCertification: '认证范围',
    selectTypeTip: '在下列产品范围内能够从事认证业务，您选择的产品是（单选项）',
    selectScopeTitle: '选择认证范围',
    selectScopeText: '有关该类产品申请VOC认证的相关文件（请仔细阅读并按相关要求进行操作）',
    certificateHolderTitle: '申请证书持有人（带',
    certificateHolderTitle2: '为必填项）',
    applicant: '申请人',
    manufacturer: '制造商',
    factoryInformation: '工厂信息',
    factoryName: '工厂名称',
    coApplicant: '同申请人',
    coManufacturer: '同制造商',
    contactPerson: '联络人',
    website: '网址',
    tradeLicense: '营业执照号码',
    invoiceMailingAddress: '形式发票',
    productParameter: '附件信息',
    productInfo: '产品信息',
    txtentOfTesting: '测试范围',
    productName: '产品名称',
    mainNumber: '主测型号',
    addingModel: '系列型号',
    tradeName: '商标',
    highestOperating: '最高工作频率(IC，晶振，谐振···等取频率最大值)',
    EUTOperatingTemperature: 'EUT Operating Temperature Range',
    EUTOperatingVoltage: 'EUT Operating Voltage Range',
    ratedVoltage: '额定电压',
    ratedPower: '额定功率',
    ratedCurrent: '额定电流',
    input: '输入',
    output: '输出',
    CEHardwareVersion: '硬件版本号(适用于 CE)',
    CESoftwareVersion: '软件版本号(适用于 CE)',
    adaptorMN: '适配器型号',
    batteryMN: '电池型号',
    adaptorRatings: '适配器参数',
    batterySpecification: '电池规格',
    password: '工程密码(适用于手机，平板产品)',
    protectionAgainst: '防触电等级',
    additionalInfo: '额外的信息，请加上附件：',
    additionalInfoText1: '1、点击“+”按钮，选择上传附件。',
    additionalInfoText2: '2、每次上传附件的大小不能超过10M，最多上传5个文件。',
    additionalInfoText3: '3、支持的文件类型为jpg、gif、png、bmp、doc、docx、xlsx、xls、pdf、txt、zip、rar。',
    internationalScope: '适用国际标准',
    EUStandard: '欧盟、非洲东南亚国家标准',
    USACanadaStandard: '美国、加拿大美洲标准',
    othersStandard: '其他国家特定标准',
    other: '其他',
    subcontract: '分包',
    isAcceptTest: '本公司不接受WSCT以外的测试报告（如未选择，视为接受）',
    handlingInstruction: '样品处理方式',
    fetchByYourself: '自取',
    toBeCollected: '快递到付',
    toBeDestroyed: '由WSCT秘密销毁',
    destroyedReminder: '* 如未选择，视为接受由WSCT销毁样品。如选择退样，但样品超过12个月后不取样或不接受快递到付，则按照放弃处理，由WSCT销毁。',
    specialVATInvoice: '增值税专用发票',
    ordinaryVATInvoice: '增值税普通发票',
    proFormaInvoice: '形式发票',
    taxpayerNumber: '纳税人识别号',
    invoiceHeader: '发票抬头(发票名称须与付款名称一致)',
    bankCode: '银行账号',
    bankName: '开户银行:',
    receiver: '收件人',
    applyingItem: '申请项目',
    newApplication: '新申请',
    modification: '修改',
    specifyReport: '请提供原始报告 / 证书号码',
    standardUpdate: '标准更新',
    alternate: '报备零部件',
    alternateModels: '报备型号',
    grandeeCode: '等级代码',
    productCode: '产品代码',
    burundiRpc: 'Burundi RPC',
    companyNo: 'Company No',
    CertifiedProduct: '认证产品',
    inputAddress: '请输入公司地址',
    inputAddressEn: '请输入公司地址（英文）',
    inputContact: '请输入联络人',
    inputUrl: '请输入网址',
    inputTradeLicense: '请输入营业执照号码',
    pleaseInput: '请输入内容',
    pleaseInputCategory: '请选择认证产品',
    pleaseInputScope: '请选择认证范围',
    pleaseCompleteForm: '请完善表单信息',
    pleaseInputCertificate: '请选择证书类型',
    pleaseSelectSampleHand: '请选择样品处理方式',
    pleaseSelectApplyType: '请选择申请类型',
    pleaseSelectStandard: '请选择适用国际规范',
    upload5Attachments: '提示：最多上传五个附件！',
    filesTips: '如有电子版资料，例如额定值或技术参数的文件，可以作为申请书的电子附件上传',
    pleaseSelectInvoiceType: '请选择发票类型',
    viewContract: '查看检测认证合同',
    pleaseInputInvoiceHeader: '请输入发票抬头',
    pleaseInputBankCode: '请输入银行账号',
    pleaseInputBankName: '请输入开户银行',
    pleaseInputReceiver: '请输入收件人',
    pleaseInputTaxpayerNumber: '请输入纳税人识别号'
  },
  setps: {
    auditAcceptance: '审核受理',
    quoteSignContract: '报价/签订合同',
    dispatchLaboratory: '签派实验室',
    uploadRawTestData: '上传原始测试数据',
    uploadDraftTestData: '上传草稿测试数据',
    firstInstance: '初审',
    reexamine: '复审',
    MakeADraft: '拟稿',
    certificateIssuance: '证书签发'
  },
  customer: {
    join: '加入黑名单',
    remove: '移出黑名单',
    user: '用户名',
    keyword: '关键词',
    email: '邮箱',
    phone: '手机号',
    company: '公司名称',
    customer: '客户类型',
    risk: '客户风险',
    payMethod: '结算方式',
    submitTime: '提交时间',
    PleaseSubmitTime: '请选择提交时间',
    check: '批量结算审核',
    waitCheck: '待审核',
    loginName: '登录名字',
    password: '密码',
    avatar: '客户头像',
    license: '营业执照',
    name: '姓名',
    legal: '公司法人',
    qq: '微信号/QQ',
    country: '国家',
    address: '地址',
    addressDetal: '地址详情',
    period: '账期',
    code: '邮编',
    joinInfo: '加入黑名单，将影响此客户所有申请业务的证书/报告签发，确定加此客户加入黑名单？',
    file: '协议上传',
    bulkFile: '批量结算协议',
    general: '普通结算',
    bulk: '批量结算',
    business: '业务',
    normal: '单次结算',
    lot: '批次结算',
    exporter: '出口商',
    manufacturer: '制造商',
    importer: '进口商',
    agency: '代理',
    china: '中国',
    kenya: 'Kenya',
    thirty: '30天',
    sixty: '60天',
    nity: '90天',
    subBusiness: '子类业务',
    result: '审批结果',
    passDate: '通过日期',
    pleaseSelect: '请选择',
    methodCheck: '结算方式审批',
    monthCheckMethod: '月度批量结算审核',
    pleaseName: '请输入姓名',
    pleasePhone: '请输入手机号',
    pleasePass: '请输入密码',
    pleasEmail: '请输入邮箱',
    pleaseType: '请选择客户类型',
    pleasCountry: '请选择国家',
    pleasMethod: '请选择结算方式',
    pleasDetail: '请输入地址详情',
    pleasPeriod: '请选择账期',
    pleasUserName: '请输入登录名字',
    pleasCompanyName: '请输入公司名称',
    pleaseFile: '请上传批量结算协议',
    pleaseImg: '请上传营业执照',
    errorPassword: '密码为8-12位，必须包含数字、字母、特殊字母',
    identifying: '客户标识',
    blackwarning: '至少选择一条数据加入黑名单',
    hasBlack: '已在黑名单当中',
    blackType: '黑名单类型',
    comfiromType: '验证方式',
    keywordsitem: '用户名/真实姓名/公司名称/手机号',
    pleaseIdentifying: '请选择客户标识',
    uploadFile: '上传文件',
    upExtension: '支持扩展名：.rar .zip .doc .docx .pdf .jpg .png',
    blackListSuspend: '暂停（客户可登录下单，但暂停发证）',
    blackListForever: '永久加入黑名单（客户不能再登录客户端并下单，管理后台也禁止为此客户下单）'
  },
  documentAudit: {
    statementNumber: '对账单号',
    companyName: '公司名称',
    settlementMethod: '结算方式',
    paymentStatus: '支付状态',
    financeStatus: '财务状态',
    financeAuditTime: '财务审核时间',
    totalAmount: '总金额',
    currency: '货币',
    amountPaid: '已付金额',
    unpaidAmount: '未付金额',
    auditStatus: '财务状态',
    paymentRecord: '支付记录',
    UploadReceipt: '上传回单',
    exprot: '导出',
    deleteReceipt: '删除回单',
    createStatement: '创建对账单',
    createStatements: '创建对账单（批量结算）',
    exportStatement: '导出对账单',
    version: '版本',
    normalVersion: '正常的版本',
    itemQuantity: '项目数量',
    pleaseSelectVersion: '请选择版本信息',
    issueDate: '签发日期',
    serviceStatus: '业务状态',
    rfcSubmitDate: 'RFC提交日期',
    create: '创建',
    quotationDetails: '报价明细',
    paymentPeriod: '账期',
    pleaseSelectRow: '请选择一行数据进行操作',
    deleteStatement: '删除回单',
    sureDeleteStatement: '确定删除对账单号：',
    sureDeleteStatement2: '的数据吗？',
    statusName: '业务状态',
    payer: '付款方',
    rfcStatus: '申请单状态',
    pleaseSelectRfc: '请先选择申请单再创建对账单',
    exportPay: '导出报价单',
    pleaseSelectminRow: '请至少选择一条数据进行操作',
    pleaseSelectmaxRow: '每次最多导出十条报价单数据',
    priceSheet: '报价单',
    pleaseSelectApplyType: '请先选择业务类型',
    checkBankInfo: '检查银行信息时出错：',
    creator: '创建人'
  },
  contractReport: {
    paymenttime: '支付时间',
    companyName: '公司名称',
    statementNumber: '对账单号',
    financeStatus: '财务状态',
    isIssue: '是否签发',
    issueDate: '签发日期',
    applicant: '申请商',
    orderDate: '下单日期',
    bookDate: '发证日期',
    payment: '付款方',
    exporter: '出口商',
    importer: '进口商',
    financialDate: '账期',
    quotedPrice: '报价金额',
    currencyType: '币种',
    USD: '对账USD',
    exchangeRate: '汇率',
    UrcNo: 'Urc No.',
    IDFNo: 'IDF No.',
    bankName: '银行名称',
    settlementMethod: '结算方式',
    paymentStatus: '支付状态',
    statusName: '业务状态',
    noData: '没有数据可以导出！',
    // 全部 ALL，
    all: '全部',
    // 常规证书
    generalCertificate: '常规证书',
    // 证书已修改
    certificateModified: '证书已修改',
    // 证书签发状态
    certificateStatus: '证书签发状态',
    identitySign: '直客/代理'
  },
  quotation: {
    notInvoiced: '未开票',
    invoiceIssued: '已开票',
    haveMailed: '已邮寄',
    query: '查询',
    financialManagement: '合同管理',
    list: '列表',
    applicationType: '申请类型',
    VOCcertification: 'VOC认证',
    NBcertification: 'NB认证',
    EntrustmentOrderStatus: '委托单状态',
    PendingAcceptance: '待受理',
    PendingCase: '待开案',
    ToBeReviewed: '待评审',
    PendingReview: '待复审',
    DraftToBeCertified: '待认证拟稿',
    importer: '进口商',
    manufacturer: '制造商',
    MANUFACTURER: '工厂信息',
    applicant: '申请商',
    QuotationAmount: '报价金额',
    FinancialStatus: '财务状态',
    SettlementMethod: '结算方式',
    accountPeriod: '账期',
    status: '业务状态',
    InvoicingInformation: '开票信息',
    operate: '操作',
    quotation: '报价',
    view: '查看',
    edit: '编辑',
    submit: '保存并发送报价单',
    TestingAndCertificationContract: '检测认证合同',
    address: '地址',
    recipient: '收件人',
    customerCode: '客户代码',
    FileNumber: '文件号',
    title2: '非常感谢您咨询WSCT的业务，以下是我们根据您提供的信息制订的报价。',
    title3: '产品名 / 型号名：待定',
    SerialNumber: '序号',
    ServiceItems: '服务项目',
    UnitPriceNetValue: '单价净值',
    taxRate: '税率(%)',
    number: '数量',
    amountOfMoney: '金额',
    serviceContent: '服务内容',
    select: '选择',
    sample: '样品',
    cycle: '周期',
    ContractAmount: '合同金额：',
    NetValue: '净值：',
    TotalTax: '税款总额：',
    TotalAmount: '总金额：',
    exchangeRate: '汇率：USA-RMB 美元兑人民币',
    exchangeRate1: '人民币兑港币',
    AccountInformation: '账户信息',
    account: '账户',
    AccountName: '户名',
    accountNumber: '账号',
    bankOfDeposit: '开户银行',
    notes: '备注',
    notes1: '付款条件：协议客户，参照协议中具体的付款条款',
    notes2: '上述估价只包括一次的测试及证书费用，而不包括复检或可能需要的个别元件测试费用。最终报价将以所收到的样品为准。客户需承担银行手续费。',
    notes3: '本报价有效期壹个月，自双方签署之日起。',
    notes4: '请提供相关技术文件，包含但不限于：a.说明书（GS 认证需法语版本） b.铭牌 c.电路图和 PCB 布局布线图 d.爆炸图（如有） e.安全元器件清单及证书复印件',
    notes5: '如无特殊要求，测量不确定度对结果判定的影响参考 IEC Guide 115',
    notes6: '服务周期基于报价所指样品通过测试，客户提交相关资料完备的测试报告完成时间。',
    notes7: '申请方确认已获得，阅读并明白 WSCT 以下服务准则 （可向 WSCT 相关员工索取）：',
    notes8: '本报价单只包括电子档报告，证书和纸质证书费用。如需纸质报告，我们将另外收取 CNY 1000 的费用，相应报价单随之发出。如需要，请在申请表格勾选“Paper copy report”。',
    notes9: '申请方认可，WSCT 对任何性质和不管如何产生的损失、损害或费用的任何赔偿责任，在任何情况下都不超过付给发生索赔的该项具体服务的费用总额的十倍或二万美元(或等值本国货币)这两个金额中较少的一个。',
    notes10: '凡因本报价单引起的或与本报价单有关的任何争议，均应提交上海国际仲裁中心进行仲裁。',
    company: '深圳市世标检测认证股份有限公司',
    footer: '如您有任何报价疑问，欢迎您随时联系我们，联系方式如下:',
    standard: '服务内容标准选择',
    pleaseSelect: '请选择',
    pleaseInput: '请输入',
    pleaseUpload: '请至少上传1个文件',
    pleaseUploads: '请检查文件是否全部上传',
    back: '返回',
    paymentstatus: '支付状态',
    Quotationamount: '报价金额',
    Currency: '币种',
    Preview: '预览',
    isInvoice: '由于更改发票信息或更改证书类型， 所以需要重新报价，请点击“保存并发送报价单”更新报价！',
    noBankInformation: '未查询到该业务的银行信息，请联系财务人员添加相关信息！',
    reQuotation: '申请重新报价',
    remark: '备注：申请成功后，等待审批，同意后会在消息中提醒。'
  },
  message: {
    allRead: '全部已读',
    message: '消息',
    messageCenter: '消息中心',
    title: '标题',
    time: '时间',
    operate: '操作',
    isread: '是否已读',
    yes: '已读',
    no: '未读',
    content: '内容'
  },
  myProfile: {
    username: '登录账号',
    name: '姓名',
    headimg: '头像',
    phone: '手机号码',
    email: '邮箱',
    sex: '性别',
    password: '登录密码',
    subordinateDepartment: '所属部门',
    currentPassword: '当前密码',
    editPassword: '新密码',
    confirmPassword: '确认密码',
    passwordReset: '密码重置',
    samePassword: '密码不一致',
    errorPassword: '8~12个字符，密码必须包含数字、字母、特殊符号',
    boy: '男',
    girl: '女',
    changeSuccess: '修改成功',
    saveSuccess: '保存成功',
    companyInformation: '公司信息',
    channelType: '渠道类型',
    compounenyNum: '统一社会信用代码',
    selectCountry: '选择国家',
    selectProvinceCityRegion: '省市区',
    pleprovince: '请输入省市区',
    addressDetail: '详细地址',
    companywebsite: '公司网址/官网',
    companyPhone: '公司电话',
    componyhumantel: '法人代表手机号',
    postcode: '邮编',
    companysize: '公司资质',
    upload: '上传',
    uploadFormat: 'jpg/jpeg/png/pdf,最大20M',
    companyintro: '公司简介',
    business: '业务归属地区',
    opinion: '意见',
    companymarketvalue: '公司市值',
    companytype: '公司类型',
    share: '股份制',
    nonShare: '非股份制',
    listed: '已上市',
    unlisted: '未上市',
    plemarketvalue: '请输入市值',
    wan: '万',
    pleregistercapital: '请输入注册资金',
    world500: '世界五百强',
    plecompanyrank: '请输入公司排名',
    service: '客服人员',
    customerService: '专属客服选择说明',
    check: '审核信息',
    opinions: '审核意见',
    customerIds: '客户身份',
    companyEmail: '公司邮箱',
    place: '位'
  },
  myProfileInfo: {
    username: '登录账号',
    name: '姓名',
    headimg: '头像',
    phone: '手机号码',
    email: '邮箱',
    sex: '性别',
    password: '登录密码',
    subordinateDepartment: '所属部门',
    currentPassword: '当前密码',
    editPassword: '新密码',
    confirmPassword: '确认密码',
    passwordReset: '密码重置',
    boy: '男',
    girl: '女',
    customerIds: '客户身份',
    mobile: '手机号码',
    WeChatOrQQ: '微信',
    selectCountry: '国家',
    selectProvinceCityRegion: '省市区',
    addressDetail: '详细地址',
    english: '(英文)',
    postcode: '公司邮箱',
    companyName: '公司名称',
    businessLicense: '营业执照',
    corporate: '公司法人',
    companyPhone: '公司电话',
    companyFax: '传真',
    remark: '备注',
    introlebal: '公司简介',
    man: '男',
    woman: '女',
    Bailor: '委托人',
    Manufacturer: '制造商',
    Factory: '生产厂',
    Agency: '代理机构',
    editMyProfile: '修改我的资料',
    uploadFile: '拖拽文件到区域内或点击上传',
    uploadFormat: 'jpg/jpeg/png/pdf,最大20M',
    imgType: '图片必须是jpg/png/jpeg/gif/bmp格式',
    imgSize: '图片大小不能超过1MB',
    licenseType: '文件必须是jpg/png/jpeg/gif/pdf格式',
    licenseLength: '营业执照最多上传五个文件',
    licenseSize: '文件大小不能超过3MB!',
    validateUserName: '请输入登录名称',
    validateUserNameLength: '"登录名称"长度为2~16个字符',
    validateUserNameFormat: '"登录名称"必须为数字或英文',
    validateName: '请输入真实姓名',
    validateNameLength: '"真实姓名"长度为2~100个字符',
    validateNameFormat: '"真实姓名"必须为中文或英文',
    validateMobile: '请输入手机号码',
    validateMobileLength: '"手机号码"长度为8~30个字符',
    validateMobileFormat: '请输入正确的"手机号码"',
    validatePass: '请输入密码',
    validatePassLength: '密码长度为6位以上',
    validatePassFormat: '"密码"必须包含数字、字母、特殊符号!"#$%&\'()*+,-./:;<=>?@[]^_`{|}~',
    validateEmail: '请输入邮箱',
    validateEmailFormat: '请输入正确的邮箱格式',
    validateZipCode: '请输入邮政编码',
    validateZipCodeFormat: '请输入4-6位数字的邮政编码',
    validateSex: '请输入性别',
    validateIdentity: '请输入客户身份',
    validateCountry: '请选择国家',
    validateProvince: '请选择省市区',
    validateAddress: '请输入地址详情',
    validateCompany: '请输入公司名称',
    validateCompanyLegal: '请输入公司法人',
    validateLicense: '请上传营业执照',
    completeMyInfo: '请完善账号信息内容',
    completeMyInfoTip: '请完善数据，完善后才能申办业务',
    Codelaceholder: '请输入验证码',
    Verificationcodesent: '已发送验证码',
    telcomfirm: '手机号验证',
    emailcomfirm: '邮箱验证',
    changeTel: '修改手机号',
    changeEmail: '修改邮箱',
    PLcomfirmWay: '请选择验证方式',
    newTel: '新手机号',
    telCode: '手机号验证码',
    emailbox: '邮箱',
    mobiletel: '手机',
    newEmail: '新邮箱',
    emailcode: '邮箱验证码',
    Scancodeverification: '扫码验证',
    hasPhoneCode: '已发送验证码到您的手机，请注意查收',
    getCode: '获取验证码',
    hasEmailCode: '已发送验证码到您的邮箱，请注意查收',
    firstgetCode: '请先获取验证码',
    changeItem: '更改密码、邮箱、手机号需要验证',
    PLinput: '请输入',
    change: '修改',
    personmessage: '联系人信息',
    identity: '身份证件',
    ID: '身份证',
    passport: '护照',
    IDnum: '身份证件',
    front: '正面',
    opposite: '背面',
    passportimg: '护照首页',
    supportsize: 'jpg/jpeg/png/pdf,最大20M',
    compouyinfo: '公司信息',
    compounenyNum: '统一社会信用代码',
    PLselect: '请选择',
    PLuploadfront: '请上传身份证正面照片',
    PLuploadopposite: '请上传身份证背面照片',
    PLuploadpassportimg: '请上传护照首页照片',
    acountInfo: '账号信息',
    register: '注册',
    Existingaccount: '已有账号？去登录',
    Login: '登录',
    Pleasefill: '请填写渠道商信息，填写信息保存后，即可申办业务',
    confirmpassword: '确认密码',
    Available: '可用于登录',
    notnameNumber: '名字不能全为数字',
    PLidNumber: '请输入身份证号',
    regitererr: '注册失败',
    Contact: '联系人信息',
    WechatOther: '微信/WhatsApp/其他',
    PLtel: '请输入号码',
    PLidNo: '请输入证件号',
    idpicture: '证件照片',
    companynum: '公司人数',
    companywebsite: '公司网址/官网',
    companyquality: '公司各资质人数',
    PLselecttype: '请选择类型',
    add: '新增',
    delete: '删除',
    companysize: '公司资质',
    // 公司简介
    companyintro: '公司简介',
    // 来源
    source: '来源',
    // WSCT官网
    WSCT: 'WSCT官网',
    // 网络
    network: '网络',
    // WSCT员工介绍
    WSCTintro: 'WSCT员工介绍',
    // 请输入介绍人名字
    intro: '请输入介绍人名字',
    // 其他
    other: '其他',
    // 请选择身份证件
    selectid: '请选择身份证件',
    // 请上传证件照片
    uploadid: '请上传证件照片',
    // 请输入证件号码
    inputid: '请输入证件号码',
    // 请上传身份证件首页
    uploadidcard: '请上传身份证件首页',
    // 请选择资格证书
    selectcertificate: '请选择资质类型',
    // 请选择资格名称
    selectcertificatename: '请选择资质名称',
    // 请输入人数
    inputnum: '请输入人数',
    // 请输入公司总人数
    inputcompanynum: '请输入公司总人数',
    // 请选择公司资质
    selectcompany: '请选择公司资质',
    // 请输入公司简介
    inputcompanyintro: '请输入公司简介',
    // WSCT员工介绍
    inputWSCTintro: '请输入介绍员工名称',
    // 不能输入介绍员工名称
    inputWSCTintroError: '不能输入介绍员工名称',
    // 文件类型错误
    fileTypeError: '文件类型错误',
    // 文件大小错误
    fileSizeError: '文件大小错误',
    // 请选择资质证书
    selectcertificateError: '请选择公司资质',
    // 长度需小于50
    lengthError: '长度需小于50',
    // 请输入来源
    PLsource: '请输入来源',
    // 请输入账号
    account: '请输入账号',
    // 请输入真实名字
    realname: '请输入真实名字',
    // 请输入邮箱
    plemail: '请输入邮箱',
    // 请输入统一社会信用代码
    unifiedsocial: '请输入统一社会信用代码',
    // 请输入公司名称
    companyname: '请输入公司名称',
    // 请输入法人姓名
    legalperson: '请输入法人姓名',
    // 请输入传真
    fax: '请输入传真',
    // 请输入地址
    address: '请输入地址',
    // 请输入邮编
    zipcode: '请输入邮箱',
    // 请输入公司人数
    companyallnum: '请输入公司人数',
    // 请输入网址
    website: '请输入网址',
    // 请输入微信/QQ
    wechatqq: '请输入微信',
    // 请输入身份证号
    idcard: '请输入身份证号',
    // 请输入备注
    remarks: '请输入备注',
    // 请输入正确的网址
    websiteError: '请输入正确的网址',
    // 注册信息确认
    registerInfo: '注册信息确认',
    person: '人',
    // 请输入密码
    plepassword: '请输入密码',
    // 渠道类型
    channelType: '渠道类型',
    // 输入公司关键词点击回车搜索公司
    searchCompany: '输入公司关键词搜索公司',
    // 请选择渠道类型
    plechannelType: '请选择渠道类型',
    // 公司
    company: '公司',
    // 个人
    personal: '个人',
    // WSCT渠道加盟
    WSCTchannel: 'WSCT渠道加盟',
    // 咨询热线0755-26996145\619226996053(海外)
    consult: '咨询热线 0755-26996145/6192 26996053(海外)',
    // 微信13823277946
    wechattel: '微信 13823277946',
    // 非凡事业，立即启航！
    start: '非凡事业，立即启航！',
    // 请输入身份证件号码
    pleidcard: '请输入身份证件号码',
    selectChannelTypea: '请选择渠道类型',
    // 请输入公司电话
    plecompanyphone: '请输入公司电话',
    // 请选择手机所属国家区号
    plecountry: '请选择手机所属国家区号',
    // 亲输入公司联系人电话
    plecontactphone: '请输入公司联系人电话',
    // 个人资质
    personalqualification: '个人资质',
    // personalintro
    personalintro: '个人简介',
    // personalresume
    personalresume: '个人简历',
    // clickOrDrag
    clickOrDrag: '点击或拖拽上传',
    // uploadFileType
    uploadFileType: '上传格式为doc/docx/pdf,大小不得超过10MB',
    // pleprovince
    pleprovince: '请输入省市区',
    // componyhumantel
    componyhumantel: '法人代表手机号',
    // upload
    upload: '上传',
    // 请输入验证码
    plecode: '请输入验证码',
    // 请输入公司电话
    plecompanytel: '请输入公司电话',
    plecompanyemail: '请输入公司邮箱',
    // plecompanywebsite
    plecompanywebsite: '请输入公司网址',
    // pleuploadcertificate
    pleuploadcertificate: '请上传资质图片',
    // plequalification
    pleequalqualification: '请完善已选择的个人资质项',
    // pleuploadresume
    pleuploadresume: '请上传个人简历',
    // uploadlimit
    uploadlimit: '超过上传限制',
    // preview
    preview: '预览',
    // 产品/样品名称
    productName: '产品/样品名称',
    // 委托人
    entrustment: '委托人',
    // 业务员
    salesman: '业务员',
    // 订单状态
    orderStatus: '订单状态',
    // 支付状态
    paymentStatus: '支付状态',
    // 报价金额
    quotationAmount: '报价金额',
    // 结算方式
    settlementMethod: '结算方式',
    // 下单时间
    orderTime: '下单时间',
    // customer
    customer: '客户标识',
    // opinion
    opinion: '意见',
    // pleopinion
    pleopinion: '请输入意见',
    // settlement
    settlement: '结算方式',
    // period
    period: '账期',
    // uploadagreement
    uploadagreement: '协议上传',
    // companytype
    companytype: '公司类型',
    // plemarketvalue
    plemarketvalue: '请输入市值',
    // pleregistercapital
    pleregistercapital: '请输入注册资金',
    // plecompanyrank
    plecompanyrank: '请输入公司排名',
    // world500
    world500: '世界五百强',
    // wan
    wan: '万',
    // 位
    place: '位',
    // service
    service: '客服人员',
    // check
    check: '审核信息',
    // result
    result: '审核结果',
    // opinions
    opinions: '审核意见',
    // plecompanytype
    plecompanytype: '请选择公司类型',
    // plecompanylist
    plecompanylist: '请选择公司是否上市',
    // plecompanymarketvalue
    plecompanymarketvalue: '请输入公司市值',
    // plecompanyregistercapital
    plecompanyregistercapital: '请输入公司注册资金',
    // plecheckresult
    plecheckresult: '请选择审核结果',
    // plecheckopinion
    plecheckopinion: '请输入审核意见',
    // plecheckperson
    plecheckperson: '请选择客服人员',
    // pass
    pass: '通过',
    // reject
    reject: '驳回',
    // share
    share: '股份制',
    // nonShare
    nonShare: '非股份制',
    // listed
    listed: '已上市',
    // unlisted
    unlisted: '未上市',
    // 是
    yes: '是',
    // 否
    no: '否',
    // plesettlement
    plesettlement: '请选择结算方式',
    // pleperiod
    pleperiod: '请选择账期',
    // pleuploadagreement
    pleuploadagreement: '请上传协议',
    // modify
    modify: '修改结算方式',
    // 业务归属地区
    business: '业务归属地区',
    // 公司市值
    companymarketvalue: '公司市值',
    // customerService
    customerService: '专属客服选择说明',
    unknown: '未知',
    customerCheckStatus: '客户资料审核状态',
    customerCheckStatus3: '个客户资料待审核'
  },
  IAFEvaluate: {
    fileDetails: '明细',
    SRNo: 'SR编号',
    PCAContract: 'PCA Contract',
    whetherExist: '是否存在类似产品',
    historicalNumber: '历史检验编号',
    riskProfiler: '风险评估',
    customerRisk: '客户风险',
    POIRisk: 'POI风险',
    productRisk: '产品风险',
    riskType: '风险类型',
    inspectionAddress: '验货地址',
    description: '描述',
    detail: '明细',
    againstType: '针对类型',
    exporter: '出口商',
    productInfo: '产品信息',
    correctiveAction: '推荐整改措施',
    rectificationRecord: '整改记录',
    detailAndResults: '产品明细和评估结果',
    applicableStandard: '适用的标准',
    evaluation: '评价',
    missingParam: '缺少或失败的参数/测试',
    acceptanceLimit: '验收限制',
    isInspect: '是否验货',
    toBeTested: '需检测',
    expiryDate: '到期日期',
    risk: '风险',
    level: '水平',
    riskLevel: '风险级别',
    selectAll: '全选',
    import: '导入',
    export: '导入',
    samplingTesting: '抽样/测试',
    samplingMethod: '列出抽样方法/指引和测试方法',
    supportingDocument: '支持文件',
    requiredSupporting: '支持符合性评定程序的文件',
    factoryAudit: '工厂审核',
    inspect: '验货',
    fileUpload: '文件上传',
    inspectionRequire: '验货图片要求',
    remarks: '备注',
    IAFVersion: 'IAF版本记录',
    time: '时间',
    IAFPTEAppraiser: 'IAF/PTE 评估人',
    operate: '操作',
    downloadPDF: '下载PDF',
    seeMore: '查看更多',
    selectAllStandard: '一键选择',
    sureSelectedStandards: '确定一键选择本页所有产品的适用标准？',
    clearAllStandard: '一键清空',
    sureClearStandards: '确定一键清空本页所有产品的适用标准？',
    sureSelectedReviews: '确定一键选择本页所有产品的评价？',
    sureInputExpiryDate: '确定一键填写本页所有产品的到期日期？',
    sureSelectedInspection: '确定一键选择本页所有产品是否验货？',
    sureSelectedTested: '确定一键选择本页所有产品是否需检测？',
    sureInput: '确定一键填写本页所有产品',
    pressEnterFill: '按Ctrl+回车键填写本页所有产品的',
    pressEnterFill2: '',
    changeProductPage: '本页产品信息未填写完整，切换页码/页数会丢失本页产品信息，是否进行切换？',
    saveIAFProduct: '保存本页产品信息',
    saveIAFProductSuccess: '保存本页产品信息成功',
    saveIAFProductError: '保存本页产品信息失败:',
    switch: '切换',
    improveInformation: '完善信息',
    inputProductId: '本页产品信息未填写完整，重新搜索会丢失本页产品信息，是否进行搜索？',
    // 请输入型号
    inputModel: '请输入型号',
    // 请输入海关编码
    inputCustomsCode: '请输入海关编码'
  },
  COCExpenseVerification: {
    Dateoissuance: '发证日期',
    FinalinvoiceFOB: '最终发票FOB Value带币种',
    QuotationFOB: '报价单FOB Value带币种',
    FOBValue: '离岸价格(USD)',
    COCFee: 'COC费用(USD)',
    RoyaltyFee: '特许权使用费(USD)',
    Exporter: '出口商',
    Importer: '进口商',
    CountryOfSupply: '供应国',
    index: '序号',
    IDFNO: 'IDF No.',
    COCNO: 'COC No.',
    RFCNO: 'RFC No.',
    UCRNo: 'UCR No.',
    route: 'Route'
  },
  logs: {
    approvalLog: '审批日志'
  },
  SRDailyPurchase: {
    SRNumber: '订单号',
    title: '标题',
    Certification: '证书号',
    COCDATE: 'COC DATE（出证日期）',
    Exporter: '出口商',
    Importer: '进口商',
    Inspection: '验货日期',
    payer: '付款人',
    LaboratoryTestingFee: '实验室检测费(RMB)',
    TOTALCOCFEEIncludingTax: 'COC含税费用总额(RMB)',
    LaboratoryTestingFeeUsd: '实验室检测费(USD)',
    TOTALCOCFEEIncludingTaxUsd: 'COC含税费用总额(USD)',
    Settlementmethod: '结算方式',
    Inspectionaddress: '验货地址',
    Inspectioncontactperson: '验货联系人',
    Inspectioncontactpersontel: '验货联系人电话',
    code: '海关编码',
    ProductDescription: '货品描述',
    CargoType: '货物类型',
    ShipmentMethod: '装运方式',
    Supervision: '是否监装',
    Inspectionnot: '是否验货',
    SubmissionTime: '提交时间',
    invoiceNo: 'Invoice No.',
    route: 'Route',
    FobValue: 'FOB VALUE',
    COCFEE: 'COC FEE (RMB)',
    VATFEE: 'VAT FEE (RMB)',
    COCFee: 'COC Fee (USD)',
    VATFEEUSD: 'VAT FEE (USD)',
    index: 'index'
  },
  Scheduled: {
    Area_code: '区域代码',
    Insp_start_date: '检验开始日期',
    Insp_end_date: '检验结束日期',
    Inspection_location: '检查位置',
    Contact_person: '联系人',
    Tel_email: '电话邮箱',
    SRNumber: 'SR订单号',
    Desc_goods: '商品描述',
    Goods_type: '商品类型',
    Quantity: '数量',
    Unit: '单元',
    Insp_StartTimeEndTime: 'Insp开始时间/结束时间',
    Container: '货柜',
    Kinds: '种类',
    Country: '国家',
    BoltSeal_no: '螺栓密封_否',
    EXPORTER: '出口商',
    IMPORTER: '进口商',
    Inspector: '检查员',
    IC_name: 'IC名称',
    IC_Office: 'IC地点',
    HSCode1: 'HS编码-1（4）',
    HSCode2: 'HS编码-2（4）',
    Inspection_result: '检查结果',
    Discrepancy_code: '差异代码',
    Comment: '评论',
    Remark: '摘要',
    InspectionRequirements: '检验要求',
    SubmissionTime: '提交时间',
    RfcUpdatedAt: 'Rfc上传时间',
    RfcStatus: 'Rfc状态',
    RfcNotinStatus: 'Rfc通知状态',
    Inspectiontime: '验货时间',
    index: '序号',
    Office: 'Office'
  },
  serviceDetail: {
    ExchangeRate: '美元/人民币汇率',
    ExchangeRate2: '欧元/美元汇率',
    COCFeeUSD: 'COC费用（美元）',
    LaboratoryTestingFeeUSD: '检测费（美元）',
    ManufacturerLicensingFeeUSD: '制造商许可费（美元）',
    RegistrationFeeUSD: '注册费（美元）',
    SamplingOfBulkShipmentsFeeUSD: '散装运输抽样费（美元）',
    ReInspectionFeeUSD: '复检费（美元）',
    OverTimeFeeUSD: '加班费（美元）',
    CancellationFeeUSD: '取消费（美元）',
    CocDate: 'COC日期',
    index: '序号',
    RFCNO: 'RFC NO.',
    CoCNO: 'COC NO.',
    Dateoissuance: '发证日期',
    route: 'Route',
    FOBValue: 'FOB Value(USD)'
  },
  audit: {
    EXPORTER: '出口商',
    IMPORTER: '进口商',
    creat: 'SR 创建日期',
    Acceptance: 'IC 受理日期',
    dispatch: 'IC 签派日期',
    dispatchc: 'CC 签派日期',
    complete: 'IAF 完成日期',
    dispatcher: '签派验货员日期',
    upload: 'IRIP 上传日期',
    Auditcompleted: 'IR IP 审核完成日期',
    uploadFD: 'FD 上传日期',
    lastupload: '最后一份文件上传日期',
    PTEcomplete: 'PTE 完成日期',
    firstDraft: '第一份草稿日期',
    lastDraft: '最后一份草稿日期',
    cocsign: 'COC 签发日期',
    SoLNumber: 'SoR/SoL 号码',
    Inspectionnot: '是否验货',
    InspectionTimedifference: '验货报告时间差',
    draftTimedifference: '草稿时间差',
    IssueStart: '发行时间',
    index: '序号',
    SR: 'SR#',
    IDF: 'IDF#',
    COC: 'COC#',
    UCR: 'UCR#',
    route: 'Route'
  },
  serviceData: {
    year: '年份',
    month: '月份',
    data: '日期',
    creat: '申请单创建数量',
    submit: '申请单提交数量',
    dispatch: '证书签发数量',
    correct: '证书修正数量',
    Newcustomers: '新客户数量',
    dayservice: '月业务数据汇总-日',
    January: '一月',
    February: '二月',
    March: '三月',
    April: '四月',
    May: '五月',
    June: '六月',
    July: '七月',
    August: '八月',
    September: '九月',
    October: '十月',
    November: '十一月',
    December: '十二月',
    total: '合计'
  },
  COCReport: {
    createdAt: '创建日期',
    certDate: '证书日期',
    exportName: '出口商',
    exportCountry: '出口国家',
    importName: '进口商',
    contract: '合同',
    number: 'UCR/GUCE/SABER 数量',
    SIRequired: '规定干预措施',
    firstIAF: '第一次IAF审核人员',
    lastIAF: '最后一次IAF审核人员',
    iLevel: '进口商服务级别',
    applicant: '申请人',
    eLevel: '出口商服务级别',
    ship: '运输方式',
    cargoType: '货物类型',
    countrySupply: '供应国',
    countryOrigin: '起运国',
    Inspector: '检查员',
    POICity: 'POI 城市',
    loadPlace: '装运港',
    dischPlace: '卸运港',
    PhysicalPSI: '是否为物理PSI',
    RemoteInspection: '远程检查',
    POICountry: 'POI 国家',
    PCARef: '物理PSI',
    description: '货物描述',
    PCARoute: 'PCA Route',
    PCATest: 'PCA检验',
    Certificate: '证书号',
    CertificateType: '证书类型',
    CertificateResultDetails: '证书结果',
    CertificateStatus: '证书状态',
    CertificateDate: '证书签发日期',
    CertificateStatusDate: '证书状态日期',
    CertifIssued: '证书发行国家',
    CertifFirstOffice: '第一次发放证书公司',
    CertifLastOffice: '最后发放证书公司',
    NumberOfLines: 'Number Of Lines',
    FoundFOBValue: 'Found FOB Value (Ref)',
    certFirstIssuedDate: '第一次证书发放日期',
    certLastIssuedDate: '最后一次证书发放日期',
    CertificateFirstIssuedBy: '第一次发证人',
    ReIssuedLater: '是否重新发证',
    SalesOffice: 'Sales Office',
    IC: 'IC',
    ScopeCertification: '认证范围',
    CreatedThrough: '通过在线门户创建',
    RFCCreateAt: 'RFC 创建日期',
    index: '序号',
    rfc: 'RFC#',
    idfno: 'IDF No.',
    CC: 'CC',
    TimeDifference: 'First IAF与First QD时间差'
  },
  SQRSOLReport: {
    exportName: '出口商',
    StatementType: '报表状态',
    Statement: 'Statement #',
    FirstIssuedDate: '第一次发证日期',
    LastIssuedDate: '最后一次发证日期',
    exportCountry: '出口国家',
    importName: '进口商',
    RFC: 'RFC #',
    Beneficiary: '受益者',
    BeneficiaryCountry: '受益国家',
    ICOffice: 'IC 办公',
    IC: 'IC',
    StatementCreationDate: '报表创建日期',
    StatementIssuedDate: '报表发行日期',
    StatementLastIssuedDate: '报表最后发行日期',
    IssuedBy: '报表发行者',
    OrderConfirmedBy: '订单确认人',
    StatementStatusDate: '报表状态日期',
    ValidForContract: '合同有效',
    NbProductIncluded: 'Nb产品包含',
    MinDate: '最小到期日',
    MaxDate: '最大到期日',
    Expired: '是否过期',
    LastFactoryDate: '工厂最后一次审核日期',
    remarks: '备注',
    createdAt: '创建日期',
    index: '序号',
    statement: 'Statement #'
  },
  certificateItem: {
    FirstIssuedDate: '第一次发证日期',
    LastIssuedDate: '最后一次发证日期',
    createdAt: '创建日期',
    certDate: '证书日期',
    exportName: '出口商',
    exportCountry: '出口国家',
    importName: '进口商',
    contract: '合同',
    number: 'UCR/GUCE/SABER 数量',
    Manufacturer: '制造商',
    CertificateNb: 'Certificate Nb',
    ItemPosition: '物品位置',
    countrySupply: '供应国',
    countryOrigin: '起运国',
    POICountry: 'POI 国家',
    DeclaredHSCode: '申报HS编码',
    FourDeclaredHSCode: '4位HS编码头',
    FourDeclaredHSCodeDes: '4位HS编码头说明',
    PCAStandard: 'PCAS标准',
    DGD: 'DGD',
    FoundGoodsCondition: '货物状况',
    Quantity: '数量',
    MesurementUnit: '测量单位',
    CertificateStLine: '航线申报离岸价格(Ref)',
    ProductReferenceSR: '产品参考SR',
    ProductPosition: '产品参考位置',
    ProductExpiry: '产品有效期',
    ICCountry: 'IC 国家',
    FactoryDate: '工厂审核完成日期',
    TransportDocumentReference: '运输单据参考',
    TestReportNumber: '测试报告编号',
    TestReportDate: '测试报告日期',
    FinalInvoiceNumber: '最终发票编号',
    FinalInvoiceDate: '最终发票日期',
    DischargePlace: '卸运港',
    index: '序号',
    rfc: 'RFC #',
    idfno: 'IDF No.'
  },
  intervention: {
    auditReportAt: '审计报告日期',
    IAFAssessAt: 'IAF评估日期',
    FirstIssuedDate: '审计报告日期',
    LastIssuedDate: '评估日期',
    createdAt: '创建日期',
    certDate: '证书日期',
    contract: '合同',
    SRId: 'SR编号',
    IDFNumber: 'IDF 数量',
    SoRSoLRef: 'SoR/SoL Ref.',
    PCARoute: 'PCA Route',
    exportName: '出口商',
    exportCountry: '出口国家',
    importName: '进口商',
    IAFNbItem: 'IAF Nb项目',
    firstIAFDate: 'First lAF Date',
    firstIAFBy: 'First lAF By',
    InspectionQuality: '检查质量',
    RemoteInspection: '远程检查',
    PhysicalInspection: '物理检查',
    EInspectionReportYN: '电子巡检报告',
    CargoType: '货物类型',
    COC: 'COC #',
    cargoType: '货物类型',
    Container: '集装箱号',
    WSCTSealUsed: '使用WSCT印章',
    SealNumber: '封条号',
    Certificate: '证书号',
    InterventionCategory: '干预范畴',
    InterventionLevel: '干预等级',
    InspectorName: '检查员',
    InspectorCountry: '检查国家',
    InspectorBranch: '督查部门',
    SchedulerName: '调度程序',
    ResourceType: '资源类型',
    InterventionRequestedDate: '申请干预日期',
    InterventionScheduledDate: '申请计划日期',
    DateofIntervention: '干预日期',
    InterventionDuration: '干预持续时间',
    InterventionResult: '干预结果',
    InterventionResultDetails: '干预结果详情',
    POIName: 'POI 名称',
    POlCountry: 'POl 国家',
    POIPostalCode: 'POI 邮政编码',
    POlCity: 'POl 城市',
    POlProvince: 'POl 省份',
    POlInterventionarea: 'POl 干预区',
    POladdress: 'POl 地址',
    InspReportIndexeddate: '检查报告日期',
    InspReportIssueddate: '督查报告日期',
    inspectionreportexpirydate: '检验报告有效期',
    COCDATE: 'COC 日期',
    InspReportReviewdate: '督查报告审阅日期',
    Discrepanciesfound: '差异',
    InterventionReviewDoneDate: '干预评估完成日期',
    InterventionReviewDoneBy: '干预措施评估人',
    AuditReportIndexed: '审计报告',
    ICOffice: 'IC ',
    index: '序号'
  },
  COCINVReport: {
    FirstIssuedDate: '第一次发证日期',
    LastIssuedDate: '最后一次发证日期',
    createdAt: '创建日期',
    certDate: '证书日期',
    exportName: '出口商',
    exportCountry: '出口国家',
    importName: '进口商',
    contract: '合同',
    SRType: 'SR类型',
    SRStatus: 'SR状态',
    Seller: '卖方',
    applicant: '申请人',
    Buyer: '买方',
    RemoteInspection: '远程检查',
    PhysicalInspection: '物理检查',
    POICountry: 'POI国家',
    POICity: 'POI城市',
    COC: 'COC #',
    cargoType: '货物类型',
    countrySupply: '供应国',
    PACRoute: 'PAC Route',
    HS: 'HS主要章节',
    CoCCategory: 'COC类别',
    CoCType: 'COC类型',
    CoCStatus: 'COC状态',
    CoCPrintedByOffice: '办公室打印COC',
    CoCIssuedByOffice: '办公室签发CoC',
    finalNumber: 'COC.最终发票号码',
    CoCFOBCurrency: 'COC FOB 货币',
    CoCFOBCValue: 'COC FOB 申报价值',
    CoCFOBCValueRef: 'COC FOB 申报价值(参考)',
    WSCTInvoiceNumber: 'WSCT发票编号',
    WSCTInvoiceIndexedDate: 'WSCT发票日期',
    InvoicingAffiliate: '发票附属',
    InvoiceCurrency: '发票货币',
    InvoiceAmount: '发票金额',
    InvoiceAmountRef: '参考发票金额',
    CertificateStatus: '证书状态',
    CertificateDate: '证书签发日期',
    CertificateStatusDate: '证书状态日期',
    CertifIssued: '证书发行国家',
    SalesOffice: '销售处',
    rate: '比率',
    Month: '月份',
    IC: 'IC',
    index: '序号',
    Sr: 'SR #',
    idf: 'IDF #'
  },
  SRReport: {
    exportName: '出口商',
    exportCountry: '出口国家',
    importName: '进口商',
    FirstIssuedDate: '审计报告日期',
    LastIssuedDate: '评估日期',
    createdAt: '创建日期',
    certDate: '证书日期',
    contract: '合同',
    CreatedthroughOnlinePortal: '通过在线门户创建',
    SrType: 'Sr 类型',
    SRforpartial: 'SR部分',
    SRId: 'SR 编号',
    IDFNumber: 'IDF 数量',
    UCRNumber: 'UCR 数量',
    SRStatus: 'SR 状态',
    StatedInterventionRequired: '规定干预措施',
    SrReceptionDate: 'Sr 接待日期',
    Importer: '进口国',
    lmporterContactName: '进口合同名称',
    ImporterContactTel: '进口合同电话',
    Exporter: '出口国',
    Applicant: '申请人 ',
    ExporterCity: '出口城市',
    ExporterCountry: '出口国',
    CountryofSupply: '供应国',
    Manufacturer: '制作商',
    ManufacturerCountry: '制造商国家',
    LoadingPlace: '装货地点',
    DischargePlace: '卸货地点',
    NumberofLines: '行数',
    DeclaredFOBValue: '申报离岸价格(参考)',
    CreatedDate: '创建日期',
    CreatedBy: '创建人',
    Orderconfirmeddate: '订单确认日期',
    OrderConfirmedBy: '确认订单',
    FirstIAFDate: '第一次IAF日期',
    FirstIAFBy: '第一次IAF审核人员',
    PCARoute: 'PCA Route',
    ICname: 'IC 名称',
    ICOffice: 'IC 办公室',
    index: '序号'
  },
  inspection: {
    Dispatch: '签派IC',
    waitDispatch: '待签派IC',
    Dispatcher: '签派IC受理员',
    Dispatchs: '签派对象',
    confirm: '确认',
    cancellation: '取消',
    referto: '最近此客户RFC受理员参考',
    dispatchrecord: 'RFC签派记录',
    Acceptedby: '受理员',
    Acceptor: '受理员',
    way: '方式',
    time: '时间',
    prompt: '注：签派会同时锁定申请单，锁定后，客户暂时不能再在客户端编辑此申请单',
    Acceptanceconfirmation: '受理确认',
    Acceptedornot: '是否受理',
    inspectthegoods: '是否验货',
    Reasonforreturn: '退回原因',
    DispatchInspectioner: '签派验货员',
    customTime: '客户要求验货/验厂时间',
    inspectTime: '检验/验厂时间',
    loseEfficacyTime: '失效日期',
    inspectionlevel: '检验等级',
    SupervisionIs: '是否监装',
    islaboratoryTesting: '是否需要实验室检测',
    Remoteinspection: '是否远程验货/验厂',
    notes: '备注',
    Auditinspectionreport: '审核检验报告',
    CabinetAndLock: '柜号及锁号',
    Cabinet: '柜号',
    lock: '锁号',
    locker: '用锁人',
    lockdate: '用锁日期',
    InspectionPhotos: '验货照片',
    InspectionReport: '验货报告',
    MergeReport: '合成报告',
    Inspectionresults: '验货结果',
    agreement: '检查员遵循以下说明和协议',
    range: '范围',
    Followtheinstructions: '按照说明进行操作',
    comment: '评论',
    AuditOperations: '审核操作',
    Passed: '已通过',
    Return: '退回修改',
    NotAccepted: '不受理（公司业务暂不涉及该验货）',
    details: '详情',
    logs: '日志',
    signIC: '签派IC/领取',
    acceptConfirm: '受理确认',
    priceSheet: '报价单',
    signInspector: '签派验货员',
    seeInspector: '查看签派验货员',
    uploadInspectionInfo: '上传验货资料',
    makeDraft: '拟稿',
    issueCertificate: '签发证书',
    upload: '上传验货资料',
    Tobeuploaded: '待上传',
    Tobeuploadeds: '待上传Online',
    InspectionResults: '验货结果',
    reserve: '保留',
    Fail: '未通过',
    InspectionTime: '检验时间',
    difference: '差异',
    poirisk: 'POI风险',
    index: '序号',
    Risktype: '风险类型',
    describe: '描述',
    detail: '明细',
    resetting: '关闭',
    submit: '提交',
    operate: '操作',
    add: '增加',
    DistanceTraveled: '行驶距离',
    Dateoftravel: '行驶日期',
    ProductConditions: '产品标识条件',
    Placeofinspection: '验货地址',
    Documents: '文件',
    Fillout: '填写采样相关信息',
    SamplingTesting: 'Sampling/Testing',
    Inspection: 'Inspection',
    PhotographsToBeProvided: 'Photographs To Be Provided',
    Remarks: 'Remarks',
    ListOfItemsToSample: 'List Of Items To Sample',
    SampledByCC: 'Sampled By CC',
    NumberOfItems: 'Number Of Items',
    State: 'State',
    Quantity: 'Quantity',
    Unit: 'Unit(Quantity)',
    Packages: 'Packages',
    Units: 'Unit(Packages)',
    Brand: 'Brand',
    ModelNumber: 'Model Number',
    GoodsDescription: 'Goods Description',
    CountryOfOrigin: 'Country Of Origin',
    UnitPrice: 'Unit Price/Currency',
    Exclude: 'Exclude/Include',
    Action: 'Action',
    Shipment: '装运',
    Cargo: '货物',
    ClosesCorrectly: 'Closes Correctly',
    ContainerNo: 'Container No./Seal No.',
    SealUser: 'Seal User',
    SealDate: 'Seal Date',
    ProductsToBeSampled: 'Products To Be Sampled',
    TestSampleReference: 'Test/Sample Reference',
    Qualitycontrol: 'Quality control',
    GlobalInspectionResults: 'GlobalInspectionResults',
    InspectionResultLeftAtPOI: 'Inspection Result Left At POI',
    TopFour: '柜号前4位',
    Lastseven: '柜号后7位',
    yes: '是',
    no: '否',
    Formalacceptance: '正式受理',
    return: '退回',
    QuantitySize: '货柜数量*尺寸',
    ContainerNumber: '拼柜号码',
    ProductsSampled: '需要抽样的产品',
    Inspector: '验货员',
    Versionrecord: '版本记录',
    continueupload: '继续上传',
    rise: '升序',
    fall: '降序',
    hscode: '海关编码',
    modelNumber: '型号',
    GoodsDescriptions: '货物描述',
    Unites: '单元',
    AssemblyStaue: '组装状态',
    Packagesfound: '找到的包的类型和数量',
    PackingCondition: '包装条件',
    Marking: '标记',
    inspectionresults: '检验结果',
    Details: '详情',
    GoodsCondition: '货物状况',
    containernocode: '货柜编号',
    document: '文档收集',
    Procedure: '检验程序',
    picture: '图片',
    sample: '抽样计划',
    container: '集装箱密封',
    check: '查看',
    conditions: '产品标识条件',
    location: '检查地点',
    ProductstoSampled: 'Products To Be Sampled',
    topoi: '检查结果留在POI',
    modeOfTransport: '运输方式',
    DispatchInspectionerdeils: '签派验货员详情',
    edit: '编辑',
    downloadPDF: '预览/下载PDF',
    uploadedit: '上传验货资料编辑',
    uploadeditOnline: 'Online上传验货资料编辑',
    inspectionIc: '签派IC/领取',
    acceptance: '受理确认',
    inspectionor: '签派验货员',
    AuditReport: '审核检验报告',
    CertificateType: '证书类型',
    RfcNO: 'RFC NO .',
    type: '申请类型',
    Route: 'Route',
    importer: '进口商',
    exporter: '出口商',
    placeOfInspection: '验货地址',
    Customertype: '公司名称',
    icAccepter: 'IC受理员',
    IAFEvaluator: 'IAF评估人',
    InspectionEndTime: '检验结束时间',
    orderStatus: '委托单状态',
    updateTime: '更新时间',
    inspectionTime: '验厂报告签发时间',
    inspectionsEndTime: '验厂报告到期时间',
    isIspect: '是否验货',
    IAFevaluateResult: 'IAF评估结果',
    inspectionDispatchViewing: '验货签派查看',
    inspectionResultViewing: '验货结果查看',
    createdTime: '创建时间',
    IAFview: 'IAF结果',
    customerIdentity: '客户类型',
    recheck: '重验',
    recheckReason: '重验原因',
    pleaserecheckReason: '请输入重验原因',
    OfflineEdit: 'Offline编辑',
    OnlineEdit: 'Online编辑',
    // 无法将
    cannotbeconverted: '无法转换',
    // 转换为数字
    toNumber: '转换为数字',
    // 被签派
    isDispatchtext: '被签派',
    // 领取
    isDispatch: '领取',
    // 请输入六位
    sixPlease: '请输入六位',
    // 请完善表格
    pleasePerfect: '请完善表格'
  },
  internalControl: {
    details: '详情',
    logs: '日志',
    signInternalControl: '签派内控',
    IAFEvaluation: 'IAF初始评估',
    PTEEvaluation: 'PTE综合评估',
    PTDAudit: 'PTD审批',
    makeDraft: '拟稿',
    // PTD审核
    reevaluationResult: '审批结果',
    approvalOpinion: '审批意见',
    reviewRecord: '审批记录',
    reevaluation: '复评',
    time: '时间',
    route: 'Route',
    importer: '进口商',
    importerPlaceholder: '请输入进口商',
    exporter: '出口商',
    exporterPlaceholder: '请输入出口商',
    applicant: '申请人',
    placeOfInspection: '验货地址',
    isIspect: '是否验货',
    inspector: '验货员',
    icAccepter: 'IC受理员',
    icAccepterElevator: 'IAF评估人',
    IAFEvaluator: 'IAF/PTD',
    PTEEvaluat: 'PTE评估',
    signControl: '签派内控',
    IAFResult: 'IAF结果',
    modifyCCAudit: '修改证书审核',
    shipmentMethod: '运输方式',
    orderStatus: '状态',
    approvalProposal: '审批结果',
    reason: '理由',
    pleaseSelectStatus: '请选择复评结果',
    pleaseSelectRecommended: '请选择审批结果',
    pleaseSelectRationale: '请选择理由',
    pleaseInputRemark: '请输入审批意见',
    ptdReviewMoreThan1: 'PTD审核后超过',
    ptdReviewMoreThan2: '小时未拟稿',
    warn: '警告',
    searchProductTip: '输入产品ID查询产品信息:39001,39002',
    factoryEndDate: '验厂报告有效期',
    UpdateTime: '更新时间',
    IAFview: 'IAF结果',
    applyType: '申请类型',
    remark: '备注',
    companyName: '公司名称',
    // 创建时间
    createTime: '创建时间',
    // 验厂报告有效期
    inspectionEndTime: '验厂报告有效期',
    // PTE评估人
    pteEvaluator: 'PTE评估人',
    // PTE评估结果
    pteEvaluationResult: 'PTE评估结果',
    // PTD评估人
    ptdEvaluator: 'PTD评估人',
    // PTD评估结果
    ptdEvaluationResult: 'PTD评估结果',
    // 复评结果
    reEvaluationResult: '复评结果',
    mookFile: '拟稿文件',
    internalControl: '内控人员',
    createdTime: '创建时间',
    // 适用标准
    standard: '适用标准',
    // 请上传.xls,.xlsx文件
    pleaseUpload: '请上传.xls,.xlsx文件'
  },
  PTEEvaluate: {
    customerAdd: '客户风险新增',
    customerEdit: '客户风险修改',
    productAdd: '产品风险新增',
    productEdit: '产品风险修改',
    title: '标题',
    applicant: '申请人',
    orderStatus: '委托单状态',
    HistoricalDocuments: '历史单据',
    HistoricalDocumentsError: '请选择表格中的一条数据',
    pleaseRiskType: '请输入风险类型',
    pleaseAgainstType: '请输入针对类型',
    listDocumenmt: '文件清单',
    attachType: '附件类型',
    fileName: '文件名称',
    uploadDate: '上传日期',
    signDate: '文件签发日期',
    select: '选择',
    isAccept: '是否采纳',
    isOpen: '是否公开',
    fileQualityEvaluate: '对质量文件的评估',
    SamplingCondition: '抽样情况',
    testCondition: '测试情况',
    testNumber: '测试报告号码',
    testReportAgency: '测试报告签发机构',
    testReportDate: '测试报告签发日期',
    labAgency: '实验室授权机构',
    testAgencyType: '测试机构类型',
    supplyFileInfo: '补充质量文件信息',
    otherReport: '其他测试报告',
    assessor: '评估人员',
    assessorDate: '评估日期',
    QMSCerificate: 'QMS证书',
    QMSCerificateWay: 'QMS证书核实途径',
    iso: '有ISO17025资质',
    tardeFile: '交易文件',
    InspectionQuality: '验货质量',
    ScopeCertification: '认证范围',
    riskEvaluate: '风险评估',
    riskType: '风险类型',
    riskSource: '风险来源',
    customerRisk: '客户风险',
    riskDescription: '风险描述',
    riskDetail: '风险明细',
    riskRFC: '风险关联RFC',
    importer: '进口商',
    creater: '制作商',
    file: '附件',
    remarks: '备注',
    againstType: '针对类型',
    exporter: '出口商',
    inspestAddress: '验货地址',
    goodsRisk: '产品风险',
    goodsInfo: '产品信息',
    advice: '推荐整改措施',
    modifyRecord: '整改记录',
    POIRisk: 'POI 风险',
    code: '海关编码',
    riskLevel: '风险等级',
    evaluateResult: '评估结果',
    differ: '差异',
    differModify: '差异整改',
    resultDetail: '结论明细',
    loseDate: '检验报告失效日期',
    yes: '是',
    no: '否',
    // 海关编码弹窗相关
    brand: '品牌',
    modle: '型号',
    number: '数量',
    unit: '单位',
    singlePrice: '单价',
    currency: '货币',
    currencyDescrib: '货币描述',
    manufacturerName: '制造商名称',
    origin: '原产国',
    CargoCondition: '货物状况',
    AssembledState: '组装状态',
    validOrNot: '有效',
    testingScopeLaboratory: '测试在实验室资质范围',
    historicalFactory: '历史验厂资料',
    factoryResult: '验厂结果',
    inspectionTime: '检验时间',
    factoryInspectionData: '验厂资料',
    validityReport: '验厂报告有效期',
    operator: '操作人',
    have: '有',
    nothing: '无',
    sureCheck: '确定一键填写所有文件',
    productDescription: '产品描述',
    rectificationMeasures: '推荐整改措施',
    PLhscode: '请选择海关编码',
    PLrisklevel: '请选择风险水平',
    assessPerson: '评估人员',
    assessDate: '评估日期',
    ApprovalResult: '审批结果',
    Reevaluation: '复评结果',
    ApprovalOpinions: '审批意见',
    Approver: '审批人员',
    ApprovalTime: '审批时间',
    ApproveProposals: '审批建议',
    Reason: '理由'
  },
  modifyCCAudit: {
    title: '修改证书CC审核',
    modifyAudit: '修改证书审核',
    modifyDate: '修改数据',
    modifyType: '修改类型',
    rawDate: '原始数据',
    newDate: '新数据',
    file: '附件',
    reason: '原因',
    remarks: '审核备注',
    pleaseSelectStatus: '请选择审核状态',
    pleaseInputRemarks: '请输入审核备注'
  },
  signControl: {
    evaluateType: 'IAF,PTE评估类型',
    pleaseEvaluateType: '请选择IAF,PTE评估类型',
    evaluator: 'IAF,PTE评估人',
    pleaseEvaluator: '请选择IAF,PTE评估人',
    PTDcheckType: 'PTD审核类型',
    pleasePTDcheckType: '请选择PTD审核类型',
    PTDchecker: 'PTD审核人',
    pleasePTDchecker: '请选择PTD审核人',
    remarks: '备注',
    RFC: 'RFC',
    date: '日期',
    historyDate: '历史此客户单签派参考数据',
    RFCRecorde: 'RFC签派内控记录',
    CCT: 'CCT',
    TCT: 'TCT'
  },
  makeDraft: {
    certInfo: '证书信息',
    unpdateInfo: '修改信息',
    submitDraft: '拟稿确认',
    makeDraft: '拟稿',
    rawDate: '原始数据',
    newDate: '新数据',
    draftRecord: '拟稿记录',
    remarks: '备注',
    pleaseRemarks: '请输入备注',
    version: '版本',
    drafter: '拟稿人',
    date: '拟稿时间',
    draftView: '拟稿预览',
    reOpen: '开启权限',
    reOpenTips: '开启验货员编辑验货资料权限？',
    draftRemarks: '编辑备注',
    draftUnit: '编辑单位',
    unit: '单位',
    newUnit: '新单位',
    draftResubmit: '拟稿确认',
    draftResubmitTips: '该操作说明客户已确认了拟稿相关信息无误, wsct代表客户确认操作, 方便及时出证！',
    description: '描述',
    PleaseDescription: '请输入描述',
    saveDraft: '保存拟稿'
  },
  parameterManagement: {
    name: '名称',
    sort: '排序号',
    tsort: '排序',
    updateTime: '更新时间',
    status: '状态',
    successUpdate: '更新成功',
    riskType: '风险类型',
    backList: '返回列表',
    description: '描述',
    core: '核心定义',
    explanatory: '注释',
    creatTime: '创建时间',
    portName: '港口名称',
    country: '所在国家',
    simpleName: '简称',
    uplevel: '上层',
    standardType: '标准类型',
    ClassFication: '标准分类编码',
    StandarNumber: '标准号',
    StandarYear: '标准年份',
    InternationalCode: '国际标准分类码',
    rule: '法规',
    SchemeType: '法规方案类型',
    language: '语言',
    RegulatoryDescription: '标准/法规描述',
    DocumentSource: '文档来源',
    EquivalentStandards: '等同标准',
    StandardTest: '标准测试项目',
    StandardState: '标准状态',
    InputCustoms: '请输入海关口号',
    InputDetail: '请输入描述',
    append: '添加',
    successmove: '移动成功',
    successsave: '保存成功',
    expand: '展开',
    retract: '收起',
    complete: '完成',
    type: '类型',
    version: '版本',
    customCode: '海关号',
    basicInfo: '基础信息',
    industybasicInfo: '行业标准基础信息（',
    annex: '标准附件',
    industyStandard: '行业标准',
    sureDel: '确认要删除吗？',
    PLname: '请输入名称',
    successAdd: '添加成功',
    PLtype: '请选择类型',
    PLportname: '请输入港口名称',
    PLcountry: '请输入所在国家名称',
    hasChildren: '含有子节点，请删除子节点。',
    alfirst: '已经是第一个',
    allast: '已经是最后一个',
    PLcode: '请输入简称',
    Delsuccess: '删除成功',
    PLscode: '请选择标准分类编码',
    PLsnumber: '请输入标准号',
    PLsyear: '请选择标准年份',
    PLnscode: '请输入国际标准分类码',
    PLlanguage: '请输入语言',
    PLstatus: '请选择标准状态',
    PLhcode: '请填写海关编码号',
    editingchange: '还处在编辑状态，请完成编辑',
    PLriskd: '请输入风险描述',
    outputMonth: '导出月报表',
    FileList: '文件列表',
    sortwarning: '排序相同，无法向上',
    sortwarninga: '排序相同，无法向下',
    PLselect: '请选择取代的标准信息',
    resmassage: '被取代标准信息',
    PLsdata: '请选择一条数据',
    notoverfour: '不能超过4个字符',
    AttachmentIntegrity: '标准附件完整度',
    Nation: '国家/组织/协会',
    Industry: '行业',
    All: '完整',
    part: '部分',
    Standards: '标准发布机构',
    Create: '创建来源',
    NotUpload: '暂无该标准的副本',
    pleaseInputInt: '请输入正整数',
    ViewCopy: '查看副本',
    CopyPreview: '副本预览',
    BZCode: '标准库编号',
    onlyPdf: '只能上传pdf文件',
    waring: '如标准发布机构不在选择中，请联系IT负责人添加',
    all: '所有',
    load: '装运港',
    unload: '卸货港',
    pleaseInputRule: '请输入法规',
    pleaseDescription: '请输入标准/法规描述',
    pleaseStandards: '请输入等同标准',
    pleaseOpen: '请选择开放平台',
    noData: '没有数据可以导出！',
    // 请输入整数
    pleaseInputIntn: '请输入正整数',
    // 该排序号不能小于0
    sortwarningb: '该排序号不能小于0',
    // 新增成功
    addsuccess: '新增成功',
    // 编辑成功
    editsuccess: '编辑成功',
    // 区号
    areaCode: '区号',
    openPlatform: '开放平台',
    deletionReason: '删除原因',
    overRange: '超过范围',
    changeAdjacent: '只要排序相邻才可以交换位置!',
    allType: '全部类型',
    standardName: '标准',
    standardStatus: '标准状态',
    sandardClassification: '标准分类'
  },
  invoice: {
    clientName: '客户名称',
    ContactPerson: '联系人',
    ClientAddress: '客户地址',
    download: '下载pdf',
    modify: '修改INVOICE',
    generate: '生成INVOICE PDF',
    InspectionTimes: '验厂时间',
    hide: '隐藏',
    hideOrders: '隐藏代理订单',
    hiddenCredential: '隐藏证书签发超过3个月数据',
    hideNot: '不隐藏',
    confirm: '确认',
    cancellation: '取消',
    invoicingForm: '发票形式'
  },
  kebqueries: {
    index: '序号',
    SerialNumber: '序列号',
    DocumentType: '文档类型',
    From: '来自',
    RfcNumber: 'Rfc编号',
    CertNumber: 'Cer编号',
    UcrNumber: 'Ucr编号',
    IdfNumber: 'idf编号',
    InvoiceNumber: '发票编号',
    Query: '查询',
    ConclusionStatus: '结论状态',
    DateOpened: '打开日期',
    CreationTime: '创建时间',
    Action: '操作',
    reply: 'reply',
    add: '新增',
    QueryAnalysis: '查询分析',
    QueryResponse: '查询响应',
    LinkToUploads: '链接到上传',
    PartnerQuery: '合作伙伴查询',
    QueriesCreate: '查询创建',
    Responses: '响应',
    ResponseFrom: '响应来自',
    ResponseDate: '回复日期',
    remind: '不能超过4000个字符',
    CustomerIdentification: '客户标识',
    agent: '渠道',
    Directcustomer: '直营',
    version: '报价版本记录',
    nothave: '暂无数据',
    Aserialnumber: '请输入序列号',
    Aqueryanalysis: '请输入查询分析',
    Aresponseinquiry: '请输入查询响应'
  },
  exchangeRate: {
    OriginalCurrency: '原币种',
    CurrencyAfterConversion: '转换后币种',
    exchangeRate: '汇率',
    state: '状态',
    operate: '操作',
    view: '查看',
    edit: '编辑',
    delete: '删除',
    add: '新增',
    cancellation: '取消',
    confirm: '确认',
    Telephone: '联系电话',
    pleaseInput: '请输入',
    order: '该订单',
    exporter: '出口商',
    importer: '进口商',
    manufacturer: '制造商',
    abnormalCustomer: '包含风险客户，请谨慎受理！'
  },
  workBench: {
    applyFor: '申请',
    inspectGoods: '验货',
    InternalControl: '内控',
    contract: '合同',
    dispatchIC: '签派IC',
    signAndIssue: '签发',
    selectLess100: '选择时间范围不能大于100天',
    issueCert: '签发证书',
    thisWeek: '本周',
    currentMonth: '本月',
    newOrder: '新订单',
    newCustomer: '新客户',
    inspectionBackend: 'WSCT验货管理后台',
    orderStatistics: '订单/证书统计'
  },
  supplement: {
    title: '标题筛选',
    productUnit: '请补充单位',
    pleaseSelect: '请选择',
    CertificateType: '证书类型',
    InspectionEndTime: '检验结束时间',
    Customertype: '公司名称',
    upphoto: '允许上传.doc.docx文件',
    upfile: '允许上传.pdf文件',
    upfiles: '允许上传.pdf文件,请上传合成好的报告',
    notquotation: '未生成报价单pdf',
    InvoiceChanges: '发票变动',
    Yes: '是',
    No: '否',
    pleaseInput: '请输入',
    pleaseUp: '请上传验货报告',
    pleaseUpPhotes: '请上传照片',
    pleaseSelectIC: '请选择签派对象',
    pleaseSelectIsInpection: '请选择是否验货',
    pleaseSelectRoute: '请选择Route',
    pleaseInputRemeks: '请输入备注',
    pleaseSelectTime: '请选择时间',
    pleaseSelectLevel: '请选择检验等级',
    pleaseSelectcondi: '请选择产品',
    pleaseSelectInspection: '请选择验货结果',
    pleaseInputKM: '请输入距离',
    pleaseSelectcondition: '请选择产品识别条件',
    pleaseSelectlocation: '请选择检查地点',
    pleaseSelectGoods: '请选择货物',
    pleaseSelectCloses: '请选择Closes Correctly',
    pleaseSelectResults: '请选择Global Inspection Results',
    pleaseSelectPOI: '请选择Inspection Result Left At POI',
    pleaseSelectSealUser: '请选择Seal User',
    pleaseSelectSealData: '请选择Seal Date',
    to: '到',
    pleaseSelecStarttTime: '请选择开始时间',
    pleaseSelecEndtTime: '请选择结束时间',
    pleaseSelecTravelStarttTime: '请选择前往时间',
    pleaseSelecTraveEndtTime: '请选择到达时间',
    pleaseSelectAudit: '请选择审核操作',
    pleaseSelectAudits: '请选择操作',
    pleaseInputAudit: '请输入评论',
    istesting: '是否验厂',
    backTop: '返回顶部',
    updateTime: '更新时间',
    receives: '领取',
    confirmreceive: '确定领取并锁定此申请单？',
    remind: '提示：锁定后客户暂时不能在客户端编辑申请单',
    theSame: '请勿上传重复文件',
    quotationTime: '报价时间',
    pleaseInputRemark: '请输入remarks',
    batchPut: '批量修改汇率',
    notQuotation: '您所选中的单中有尚未报价的单',
    notCurrency: '您所选中的单中有不同币种',
    exchangerate: '汇率修改为',
    modifyrate: '请输入修改后的汇率',
    pleaseInputdelies: '注意：批量修改已选中RFC单的汇率，会影响所有选中RFC单的报价，确定修改?',
    pleaseInputa: '请输入正确汇率',
    pleaseSelectSample: '请选择需要抽样的产品',
    pleaseSelectExchangeRate: '请选择需要批量修改汇率的订单',
    noApplyType: '请选择相同的申请类型',
    noApplyTypeCountry: '申请类型国家不一致',
    notSQLSORFalg: 'SOL、SOR类型订单无需批量修改汇率'
  }
}
