/**
 * 字典
 */
import { createAxios } from '@/utils/request'

const request = createAxios()
const urlBasePrefix = process.env.VUE_APP_URL_BASE

const slugList = [
  'rfc.rfc_status', // rfc状态
  'new_rfc.rfc_ststus', // rfc全部状态
  'inspection.route', // route类型
  'rfc.kenya.transport_mode', // 运输方式
  'rfc.kenya.need_cert_person', // 邮件证书领取
  'rfc.kenya.packing_method', // 运输方式
  'rfc.kenya.is_get_idf_no', // 是否以获取到IDF
  'rfc.kenya.quantity_delivered', // 交付数量
  'rfc.currency', // 币种
  'rfc.apply.file.type', // 上传附件类型
  'rfc.freight_status', // 货物状态
  'rfc.assemble_status', // 装货状态
  'rfc.trade_term', // 贸易术语
  'rfc.iraq.quantity_delivered', // 交货类型
  'transport_info.pack_info', // 包装信息
  'transport_info.export_code', // 出口国家代号
  'rfc.province', // 检验地点-城市
  'customer.identity_sign', // 客户标识
  'customer.identity', // 客户类型 渠道类型
  'customer.settlement_mode', // 结算方式
  'customer.monthly_review', // 月度批量结算审核
  'customer.settlement_approval', // 结算方式审批
  'finance.pay_status', // 支付状态
  'finance.pay_confirm_status', // 财务审核状态
  'inspection.check_report_status', // 审核操作
  'inspection.inspect_status', // 线下验货结果
  'inspection.inspect_status_online', // 线上验货结果
  'inspection.level', // 验货等级
  'risk-product-record.risk_level', // 产品风险水平
  'finance.bill.export_type', // 导出账单类型
  'inspection.close_correctly', // 正确关闭 Closes Correctly
  'inspection.shipment_type', // 货物
  'inspection.place_type', // 线上检验地点
  'rfc.freight_status', // 货物描述
  'inspection.product_identify_type', // 线上产品识别条件
  'kebs.month_report.type', // kebs月报表类型
  'kebs.month_report.version', // kebs月报表版本
  'pte.test_status', // 测试状态
  'pte.sample_status', // 抽样情况数据
  'pte.test_sign_lab', // 测试机构数据
  'pte.qms_cert_status', // QMS证书数据
  'pte.qms_cert_check_status', // QMS证书审核状态
  'pte.deal_file_status', // 交易文件数据
  'pte.assess_status.rl', // 评估结果数据 sor/sol
  'pte.assess_status', // 评估结果数据 coc
  'pte.discrepancies', // 差异数据
  'pte.discrepancies_corrective', // 差异整改数据
  'pte.inspection_status', // 验货质量数据
  'pte.cert_scope', // 认证范围
  'ptd.assess_check_recommended', // 审批建议
  'ptd.assess_check_rationale', // 审批理由
  'ptd.assess_check_status', // 审批结果
  'risk-record.serve_type', // 针对类型
  'basic_standard.status', // 标准状态
  'basic.industry', // 行业分类
  'site', // 来源
  'basic_standard_dictionary.type', // 标准类型
  'cert.update_type', // 证书修改类型
  'inspection.product.packing_condition', // 包装
  'inspection.product.marking', // 标记
  'inspection.product.inspection_result', // 检查结果
  'cert.status', // 证书状态
  'keb.cert_type', // 证书类型
  'rfc.cargo.operator', // 评估类型,
  'rfc.iraq.packing_method', // 伊拉克申请单出运方式
  'rfc.iraq.transport_mode', // 伊拉克申请单运输方式
  'basic.currency', // 公共币种
  'customer.identity_sign', // 客户标识
  'customer.blacklist', // 黑名单类型,取id
  'customer.blacklist.code', // 黑名单类型,取code
  'customer.qualification', // 公司资质
  'basic.big_zone', // 业务归属地
  'customer.account_period', // 账期
  'invoice.invoice_version' // 发票版本
]

// 所有字典
export const getDictionary = () => {
  return request({
    method: 'GET',
    url: `${urlBasePrefix}/common/getDictionaryList?slugs=${slugList}&langId=${localStorage.getItem('LANG') || 'zh_CN'}`
  })
}
// // 检测任务 任务类型
// export const acceptStatus = { 1: '受理', '-1': '退回' }
// // 样品是否返回
// export const sampleNeedBackList = { '-1': '否（WSCT自行处理）', 1: '是' }
// // 检测状态是否异常
// export const isAbnormal = { 1: '是', 2: '否' }
// // 检验结果审核状态
// export const detectionStatus = { 0: '待初审', 1: '审核通过', 2: '退回', 3: '待复审' }
// // 配置管理 产品认证状态
// export const productsType = { '-1': '禁用', 1: '启用' }
