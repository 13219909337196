/**
 * 基础信息接口
 */
import { createAxios } from '@/utils/request'

const request = createAxios()
const basePrefix = process.env.VUE_APP_URL_BASE
const urlPrefixIraq = process.env.VUE_APP_URL_API

// *************************业务类型 start***********************************

// 业务列表
export const getApplicationType = () => {
  return request({
    method: 'GET',
    url: `${basePrefix}/commonAuth/getApplicationTypeList`,
    params: {
      applyTypeCode: 'export_product_certificate'
    }
  })
}

// 获取业务类型下的子类
export const getApplicationTypeItem = (id: any) => {
  return request({
    method: 'GET',
    url: `${basePrefix}/commonAuth/getApplicationTypeItemsList/${id}`
  })
}

// *************************业务类型 end***********************************
// **************************委托申请单************************************
// 获取申请中列表
export const getRfcList = (params: any) => {
  return request({
    method: 'GET',
    url: `${urlPrefixIraq}/rfc/getList`,
    params
  })
}

// 获取申请单excel数据
export const getApplicationExcel = (data: any) => {
  return request({
    method: 'POST',
    url: `${urlPrefixIraq}/rfc/excelImport`,
    data
  })
}

// 申请单调流程
export const jumpWorkFlow = (data: any) => {
  return request({
    method: 'POST',
    url: `${urlPrefixIraq}/rfc/adjustmentOrder`,
    data
  })
}
// **************************申请中的单据************************************
// 获取申请中的单据列表

/*
 * 删除申请草稿
 */
export const deleteRfc = (id: any) => {
  return request({
    method: 'POST',
    url: `${urlPrefixIraq}/rfc/deleteById/${id}`
  })
}

// **************************暂停订单************************************
// 暂停订单列表
export const getRfcSuspendList = (params: any) => {
  return request({
    method: 'GET',
    url: `${urlPrefixIraq}/rfc/getSuspendPage`,
    params
  })
}
// 恢复暂停订单
export const restoreApplication = (data : any) => {
  return request({
    method: 'POST',
    url: `${urlPrefixIraq}/rfc/recoverPauseRfc`,
    data
  })
}
// ***************************申请单********************************
// /*
//  * 添加申请单
//  */
export const createApplication = (data: object) => {
  return request({
    method: 'POST',
    url: `${urlPrefixIraq}/rfc/addRfc`,
    data
  })
}

/*
* 查询申请单详情
*/
export const queryApplicationDeta = (id: any) => {
  return request({
    method: 'GET',
    url: `${urlPrefixIraq}/rfc/getRfcById?id=${String(id)}`
  })
}

/*
* 查询出口商、进口商、检验地点历史数据
*/
export const getBaseHistoryList = (params: any) => {
  return request({
    method: 'GET',
    url: `${urlPrefixIraq}/rfcCompany/getCompanyList`,
    params
  })
}

/*
 * 获取委托申请人信息
 */
export const getCustomerInfo = (queryStr: string) => {
  return request({
    method: 'GET',
    url: `${urlPrefixIraq}/customerAll/page`,
    params: {
      queryStr
    }
  })
}

/*
* 优先处理订单
*/
export const priorityOrder = (rfcIds: number[], isPriority: any) => {
  return request({
    method: 'POST',
    url: `${urlPrefixIraq}/rfc/updateRfcStatus`,
    data: {
      rfcIds,
      isPriority
    }
  })
}

/*
* 下载申请单pdf
*/
export const downloadPDF = (id: any) => {
  return request({
    method: 'GET',
    url: `${urlPrefixIraq}/rfc/rfcPdf/${id}`
  })
}
// ****************** 申请单产品管理  *******************************
// 获取产品列表
export const getProductList = (params: any) => {
  return request({
    method: 'GET',
    url: `${urlPrefixIraq}/rfcProduct/page`,
    params
  })
}
// 修改产品信息
export const editProduct = (data: any) => {
  return request({
    method: 'POST',
    url: `${urlPrefixIraq}/rfcProduct/update`,
    data
  })
}

// 删除产品
export const deleteProduct = (id: number) => {
  return request({
    method: 'POST',
    url: `${urlPrefixIraq}/rfcProduct/del`,
    data: {
      id
    }
  })
}

// 新增产品
export const addProduct = (data: any) => {
  return request({
    method: 'POST',
    url: `${urlPrefixIraq}/rfcProduct/add`,
    data
  })
}

// 获取excel产品列表
export const getExcelProduct = (data: any) => {
  return request({
    method: 'POST',
    url: `${urlPrefixIraq}/rfcProduct/import`,
    data
  })
}

// 导出产品列表
export const exportProductData = (id: any) => {
  return request({
    method: 'POST',
    url: `${urlPrefixIraq}/rfcProduct/export`,
    data: {
      cargoRfcId: id
    },
    responseType: 'blob'
  })
}
// 产品一键清空-Java
export const clearProduct = (data: { cargoRfcId: string | string[] }) => {
  return request({
    method: 'POST',
    url: `${urlPrefixIraq}/rfcProduct/clear`,
    data
  })
}
// ******************  恢复附件  *******************************
// 恢复附件列表
export const queryRestoreFileList = (id: any) => {
  return request({
    method: 'GET',
    url: `${urlPrefixIraq}/rfcFile/regainFileList`,
    params: { cargoRfcId: id }
  })
}
// 恢复文件
export const restoreFile = (id: any) => {
  return request({
    method: 'POST',
    url: `${urlPrefixIraq}/rfcFile/regainFile`,
    data: {
      id
    }
  })
}
// 一键下载附件
export const oneClickDownload = (data: any) => {
  return request({
    method: 'POST',
    url: `${urlPrefixIraq}/rfcFile/exportZIP`,
    data,
    responseType: 'blob'
  })
}
// 新增附件--Java
export const addFile = (data: any) => {
  return request({
    method: 'POST',
    url: `${urlPrefixIraq}/rfcFile/save`,
    data
  })
}
// 申请中单据列表
export const getDocumentList = (data: any) => {
  return request({
    method: 'GET',
    url: `${urlPrefixIraq}/rfc/getDocumentList`,
    params: data
  })
}
// ****************** 基础信息  *******************************

// 取消 暂停订单
export const suspendOrder = (rfcIds: number[], rfcStatus: any, remark:any) => {
  return request({
    method: 'POST',
    url: `${urlPrefixIraq}/rfc/updateRfcStatus`,
    data: {
      rfcIds,
      rfcStatus,
      remark
    }
  })
}

// 恢复暂停订单
// 获取公司列表
export const getCustomerList = () => {
  return request({
    method: 'GET',
    url: `${urlPrefixIraq}/customer/getCustomerList`
  })
}
